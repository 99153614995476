import { Box, FormControl, FormLabel, Grid, Input } from "@chakra-ui/react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Controller, useFormContext } from "react-hook-form";

interface BirthPlaceFormProps {
  formPrefix?: string;
}

export const BirthPlaceForm: React.FC<BirthPlaceFormProps> = ({
  formPrefix,
}) => {
  const { setValue } = useFormContext();

  const handleSelect = (newValue: any) => {
    if (newValue !== null) {
      geocodeByPlaceId(newValue.value.place_id)
        .then((geocodeResults) => {
          const addressComponents = geocodeResults[0].address_components;
          const city = addressComponents?.find((component) =>
            component.types.includes("locality")
          )?.long_name;
          const country = addressComponents?.find((component) =>
            component.types.includes("country")
          )?.long_name;

          const prefix = formPrefix ? `${formPrefix}.` : "";
          setValue(`${prefix}birth.city`, city);
          setValue(`${prefix}birth.country`, country);
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Box>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "1fr",
          lg: "1fr",
        }}
        gap={4}
        my={4}
      >
        <PlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          debounce={500}
          selectProps={{
            placeholder: "Rechercher ville de naissance...",
            noOptionsMessage: () => "Pas de résultats",
            loadingMessage: () => "Chargement...",
            onChange: handleSelect,
          }}
        />

        <FormControl id="birth_department">
          <FormLabel>Département de naissance</FormLabel>
          <Controller
            name={`${formPrefix ? `${formPrefix}.` : ""}birth.department`}
            render={({ field }) => (
              <Input
                {...field}
                pattern="^[0-9]{1,2}$"
                maxLength={2}
                placeholder="00"
              />
            )}
          />
        </FormControl>

        <FormControl id="birth_city">
          <FormLabel>Ville de naissance</FormLabel>
          <Controller
            name={`${formPrefix ? `${formPrefix}.` : ""}birth.city`}
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>

        <FormControl id="birth_country">
          <FormLabel>Pays de naissance</FormLabel>
          <Controller
            name={`${formPrefix ? `${formPrefix}.` : ""}birth.country`}
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>
      </Grid>
    </Box>
  );
};
