import ExcelJS from "exceljs";
import { fetchZonesForEventAndService } from "../../../api/zones/VacatairesToZone";
import { PersonnelRolesLabels } from "../../../utils/types/EnumPost";
import { STATUS_ACCEPT } from "./EvenementZones";

export const generateServiceXLSX = async (service: any, event: any) => {
  try {
    const zones = await fetchZonesForEventAndService(event._id, service.name);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Feuille de Margement");

    const serviceHeaderData = [
      ["Événement", event?.name_event],
      [
        "Date",
        event?.date_event
          ? new Date(event.date_event * 1000).toLocaleDateString("fr-FR", {
              weekday: "long",
              day: "2-digit",
              month: "long",
              year: "numeric",
            })
          : "Aucune date d'évènement spécifiée",
      ],
      [
        "Lieu",
        event?.place
          ? event.place.name
          : "Aucune adresse d'évènement spécifiée",
      ],
      ["Service", service.name],
      // ["Zone", zone?.name ? zone.name : "Aucune zone spécifiée"],
      [
        "Référence",
        event?.analytic_code
          ? event.analytic_code
          : "Aucun code analytique spécifiée",
      ],
      ["id", event._id],
    ];

    serviceHeaderData.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const excelCell = worksheet.getCell(rowIndex + 1, cellIndex + 1);
        excelCell.value = cell;
        if (cellIndex === 0) {
          excelCell.font = { color: { argb: "FFFF0000" } }; // Rouge
        } else {
          excelCell.font = { bold: true };
        }
      });
    });

    let currentRow = serviceHeaderData.length + 2;

    zones.forEach((zone: any) => {
      const vacatairesFiltres = zone.vacataires.filter(
        (vacataire: any) => vacataire.status === STATUS_ACCEPT
      );

      const zoneCell = worksheet.getCell(currentRow, 1);
      zoneCell.value = `Zone: ${zone.name}`;
      zoneCell.font = { bold: true }; // Appliquer un style gras
      worksheet.mergeCells(currentRow, 1, currentRow, 10);
      currentRow++;

      const headers = [
        "NOM",
        "PRENOM",
        "POSTE",
        "HEURE DE CONVOCATION",
        "HEURE REELLE D'ARRIVEE",
        "SIGNATURE À L'ARRIVEE",
        "TEMPS DE PAUSE",
        "HEURE DE FIN",
        "SIGNATURE AU DEPART",
        "NOMBRE D'HEURES TOTAL",
      ];
      headers.forEach((header, index) => {
        const headerCell = worksheet.getCell(currentRow, index + 1);
        headerCell.value = header;
        headerCell.font = { bold: true };
      });
      currentRow++;

      // Ajouter les données des vacataires
      vacatairesFiltres.forEach((vacataire: any) => {
        const convertTimestampToFrenchHour = (timestamp: number) => {
          const date = new Date(timestamp * 1000);
          return date.toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          });
        };

        const startHourFrench = vacataire.startHour
          ? convertTimestampToFrenchHour(vacataire.startHour)
          : "Non renseigné";

        const roleLabel =
          PersonnelRolesLabels[
            vacataire.fonction as keyof typeof PersonnelRolesLabels
          ] || "Non renseigné";
        const row = [
          vacataire.name,
          vacataire.firstname,
          roleLabel || "Non renseigné",
          startHourFrench,
          "",
          "",
          "",
          "",
          "",
          "",
        ];
        row.forEach((cell, index) => {
          worksheet.getCell(currentRow, index + 1).value = cell;
        });
        currentRow++;
      });

      currentRow++; // Ligne vide après chaque zone
    });

    worksheet.columns = [
      { width: 25 },
      { width: 25 },
      { width: 40 },
      { width: 25 },
      { width: 25 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
    ];
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = `${service.name}_feuille_de_margement.xlsx`;

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error("Error generating Excel for service:", error);
  }
};

export const generateEventXLSX = async (event: any) => {
  try {
    const zones = await fetchZonesForEventAndService(
      event._id,
      event.coop.name
    );

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Feuille de Margement");

    const eventHeaderData = [
      ["Événement", event?.name_event],
      [
        "Date",
        event?.date_event
          ? new Date(event.date_event * 1000).toLocaleDateString("fr-FR", {
              weekday: "long",
              day: "2-digit",
              month: "long",
              year: "numeric",
            })
          : "Aucune date d'évènement spécifiée",
      ],
      [
        "Lieu",
        event?.place
          ? event.place.name
          : "Aucune adresse d'évènement spécifiée",
      ],
      [
        "Référence",
        event?.analytic_code
          ? event.analytic_code
          : "Aucun code analytique spécifiée",
      ],
      ["id", event._id],
    ];

    eventHeaderData.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const excelCell = worksheet.getCell(rowIndex + 1, cellIndex + 1);
        excelCell.value = cell;
        if (cellIndex === 0) {
          excelCell.font = { color: { argb: "FFFF0000" } }; // Rouge
        } else {
          excelCell.font = { bold: true };
        }
      });
    });

    let currentRow = eventHeaderData.length + 2;
    for (const service of event.coop) {
      worksheet.getCell(currentRow, 1).value = "Service";
      worksheet.getCell(currentRow, 1).font = { color: { argb: "FFFF0000" } }; // Rouge
      worksheet.getCell(currentRow, 2).value = service.name;
      worksheet.getCell(currentRow, 2).font = { bold: true }; // Gras
      currentRow++;
      const zones = await fetchZonesForEventAndService(event._id, service.name);

      zones.forEach((zone: any) => {
        worksheet.getCell(currentRow, 1).value = "Zone";
        worksheet.getCell(currentRow, 1).font = { color: { argb: "FFFF0000" } }; // Rouge
        worksheet.getCell(currentRow, 2).value = zone.name;
        worksheet.getCell(currentRow, 2).font = { bold: true }; // Gras
        currentRow++;
        const vacatairesFiltres = zone.vacataires.filter(
          (vacataire: any) => vacataire.status === STATUS_ACCEPT
        );

        const headers = [
          "NOM",
          "PRENOM",
          "POSTE",
          "HEURE DE CONVOCATION",
          "HEURE REELLE D'ARRIVEE",
          "SIGNATURE À L'ARRIVEE",
          "TEMPS DE PAUSE",
          "HEURE DE FIN",
          "SIGNATURE AU DEPART",
          "NOMBRE D'HEURES TOTAL",
        ];
        headers.forEach((header, index) => {
          const headerCell = worksheet.getCell(currentRow, index + 1);
          headerCell.value = header;
          headerCell.font = { bold: true };
        });
        currentRow++;

        // Ajouter les données des vacataires
        vacatairesFiltres.forEach((vacataire: any) => {
          const convertTimestampToFrenchHour = (timestamp: number) => {
            const date = new Date(timestamp * 1000);
            return date.toLocaleTimeString("fr-FR", {
              hour: "2-digit",
              minute: "2-digit",
            });
          };

          const startHourFrench = vacataire.startHour
            ? convertTimestampToFrenchHour(vacataire.startHour)
            : "Non renseigné";

          const roleLabel =
            PersonnelRolesLabels[
              vacataire.fonction as keyof typeof PersonnelRolesLabels
            ] || "Non renseigné";
          const row = [
            vacataire.name,
            vacataire.firstname,
            roleLabel || "Non renseigné",
            startHourFrench,
            "",
            "",
            "",
            "",
            "",
            "",
          ];
          row.forEach((cell, index) => {
            worksheet.getCell(currentRow, index + 1).value = cell;
          });
          currentRow++;
        });

        currentRow++; // Ligne vide après chaque zone
      });
    }

    worksheet.columns = [
      { width: 25 },
      { width: 25 },
      { width: 40 },
      { width: 25 },
      { width: 25 },
      { width: 30 },
      { width: 20 },
      { width: 20 },
      { width: 30 },
      { width: 30 },
      { width: 20 },
    ];
    // Sauvegardez le fichier
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = `${event.name}_feuille_de_margement.xlsx`;

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  } catch (error) {
    console.error("Error generating Excel for event:", error);
  }
};
