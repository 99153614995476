// DeleteEventModal.tsx

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";
import { useMutation } from "react-query";
import { deleteEvent } from "../../../api/ApiEvenement";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  eventId: string | null;
  onDeleteSuccess: () => void;
};

const DeleteEventModal: React.FC<Props> = ({
  isOpen,
  onClose,
  eventId,
  onDeleteSuccess,
}) => {
  const deleteEventMutation = useMutation<void, Error, string>(deleteEvent, {
    onSuccess: () => {
      onDeleteSuccess();
      onClose();
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Supprimer l'événement</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Êtes-vous sûr de vouloir supprimer cet événement ?
        </ModalBody>
        <ModalFooter>
          <Button mr={3} variant="ghost" onClick={onClose}>
            Annuler
          </Button>
          <Button
            colorScheme="red"
            onClick={() => {
              if (eventId) {
                deleteEventMutation.mutate(eventId);
              }
            }}
          >
            Confirmer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteEventModal;
