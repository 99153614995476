import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  addNewCategory,
  deleteCategory,
  getCategory,
} from "../../../../api/orga/Category";
import {
  addNewChampionship,
  deleteChampionship,
  getChampionship,
} from "../../../../api/orga/Championship";
import {
  addNewPlace,
  deletePlace,
  getPlaces,
  PlaceType,
} from "../../../../api/orga/Place";

import {
  addNewSeason,
  deleteSeason,
  getSeasons,
} from "../../../../api/orga/Season";
import { addNewTeam, deleteTeam, getTeams } from "../../../../api/orga/Team";
import { GetInput } from "../../../../utils/types/EventType";
import CustomSelect from "./CustomSelect";
import PlaceSelect from "./CustomSelectPlace";

export const EventsFormField: React.FC = () => {
  const {
    register,
    formState: { errors },
    watch,
    getValues,
  } = useFormContext();

  const currentFormValues = watch();
  const [startEvent, setStartEvent] = useState<Date | null>(new Date());
  const [endEvent, setEndEvent] = useState<Date | null>(new Date());
  const [htmlContent, setHtmlContent] = useState<string>("");
  const watchedCategory = watch("category");
  const watchedPlace = watch("place");
  const watchedHomeTeam = watch("home_team");
  const watchedAwayTeam = watch("away_team");
  const watchedSeason = watch("season");
  const watchedChampionship = watch("championship");

  const handleContentChange = (content: string) => {
    setHtmlContent(content);
  };

  const queryClient = useQueryClient();
  const internalCheckbox = watch("internalCheckbox");
  const externalCheckbox = watch("externalCheckbox");
  //Teams
  const {
    data: teams,
    isLoading: teamsLoading,
    error: teamsError,
  } = useQuery<GetInput[], Error>("teams", getTeams);
  const {
    data: category,
    isLoading: categoryLoading,
    error: categoryError,
  } = useQuery("category", getCategory);

  const toast = useToast();
  const [teamInput, setTeamInput] = useState("");
  const [awayTeamInput, setAwayTeamInput] = useState("");
  const [isTeamOpen, setIsTeamOpen] = useState(false);
  const [isAwayTeamOpen, setIsAwayTeamOpen] = useState(false);
  const handleTeamInputChange = (e: any) => setTeamInput(e.target.value);
  const handleAwayTeamInputChange = (e: any) =>
    setAwayTeamInput(e.target.value);

  const addTeamMutation = useMutation(addNewTeam, {
    onSuccess: () => {
      queryClient.invalidateQueries("teams");
    },
  });

  const deleteTeamMutation = useMutation(deleteTeam, {
    onSettled: () => {
      queryClient.invalidateQueries("teams");
    },
  });

  const handleAddTeam = () => {
    if (
      teamInput &&
      !teams?.some((team: GetInput) => team.name === teamInput)
    ) {
      addTeamMutation.mutate(teamInput);
      toast({
        title: `Équipe ajoutée`,
        description: `L'équipe ${teamInput} a été ajoutée`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setTeamInput("");
    }
  };

  const handleAddAwayTeam = () => {
    if (
      awayTeamInput &&
      !teams?.some((team: GetInput) => team.name === awayTeamInput)
    ) {
      addTeamMutation.mutate(awayTeamInput);
      toast({
        title: `Équipe ajoutée`,
        description: `L'équipe ${awayTeamInput} a été ajoutée`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setAwayTeamInput("");
    }
  };

  const handleSelectTeam = (team: GetInput) => {
    setTeamInput(team.name);
    setIsTeamOpen(false);
  };

  const handleSelectAwayTeam = (team: GetInput) => {
    setAwayTeamInput(team.name);
    setIsAwayTeamOpen(false);
  };

  const handleDeleteTeam = (team: GetInput) => {
    deleteTeamMutation.mutate(team._id);
    toast({
      title: `Équipe supprimée`,
      description: `L'équipe ${team.name} a été supprimée`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setTeamInput("");
  };

  const handleDeleteAwayTeam = (team: GetInput) => {
    deleteTeamMutation.mutate(team._id);
    toast({
      title: `Équipe supprimée`,
      description: `L'équipe ${team.name} a été supprimée`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setAwayTeamInput("");
  };

  useEffect(() => {
    if (watchedHomeTeam && watchedHomeTeam.name) {
      setTeamInput(watchedHomeTeam.name);
    }
  }, [watchedHomeTeam]);

  useEffect(() => {
    if (watchedAwayTeam && watchedAwayTeam.name) {
      setAwayTeamInput(watchedAwayTeam.name);
    }
  }, [watchedAwayTeam]);

  // Grouping Team related states and handlers
  const teamState = {
    inputValue: teamInput,
    isOpen: isTeamOpen,
    setIsOpen: setIsTeamOpen,
    handleInputChange: handleTeamInputChange,
    handleAdd: handleAddTeam,
    handleSelect: handleSelectTeam,
    handleDelete: handleDeleteTeam,
  };

  const AwayteamState = {
    inputValue: awayTeamInput,
    isOpen: isAwayTeamOpen,
    setIsOpen: setIsAwayTeamOpen,
    handleInputChange: handleAwayTeamInputChange,
    handleAdd: handleAddAwayTeam,
    handleSelect: handleSelectAwayTeam,
    handleDelete: handleDeleteAwayTeam,
  };

  // Season
  const [seasonInput, setSeasonInput] = useState("");
  const [isSeasonOpen, setIsSeasonOpen] = useState(false);

  const {
    data: seasons,
    isLoading: seasonsLoading,
    error: seasonsError,
  } = useQuery<GetInput[], Error>("seasons", getSeasons);

  const handleSeasonInputChange = (e: any) => setSeasonInput(e.target.value);

  const addSeasonMutation = useMutation(addNewSeason, {
    onSuccess: () => {
      queryClient.invalidateQueries("seasons");
    },
  });

  const deleteSeasonMutation = useMutation(deleteSeason, {
    onSettled: () => {
      queryClient.invalidateQueries("seasons");
    },
  });

  const handleAddSeason = () => {
    if (
      seasonInput &&
      !seasons?.some((season: GetInput) => season.name === seasonInput)
    ) {
      addSeasonMutation.mutate(seasonInput);
      toast({
        title: `Saison ajoutée`,
        description: `La saison ${seasonInput} a été ajoutée`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setSeasonInput("");
    }
  };

  const handleSelectSeason = (season: GetInput) => {
    setSeasonInput(season.name);
    setIsSeasonOpen(false);
  };

  const handleDeleteSeason = (season: GetInput) => {
    deleteSeasonMutation.mutate(season._id);
    toast({
      title: `Saison supprimée`,
      description: `La saison ${season.name} a été supprimée`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setSeasonInput("");
  };

  useEffect(() => {
    if (watchedSeason && watchedSeason.name) {
      setSeasonInput(watchedSeason.name);
    }
  }, [watchedSeason]);

  // Grouping Season related states and handlers
  const seasonState = {
    inputValue: seasonInput,
    isOpen: isSeasonOpen,
    setIsOpen: setIsSeasonOpen,
    handleInputChange: handleSeasonInputChange,
    handleAdd: handleAddSeason,
    handleSelect: handleSelectSeason,
    handleDelete: handleDeleteSeason,
  };

  //Championship
  const [championshipInput, setChampionshipInput] = useState("");
  const [isChampionshipOpen, setIsChampionshipOpen] = useState(false);

  const {
    data: championships,
    isLoading: championshipsLoading,
    error: championshipsError,
  } = useQuery<GetInput[], Error>("championships", getChampionship);

  const handleChampionshipInputChange = (e: any) =>
    setChampionshipInput(e.target.value);

  const addChampionshipMutation = useMutation(addNewChampionship, {
    onSuccess: () => {
      queryClient.invalidateQueries("championships");
    },
  });

  const deleteChampionshipMutation = useMutation(deleteChampionship, {
    onSettled: () => {
      queryClient.invalidateQueries("championships");
    },
  });

  const handleAddChampionship = () => {
    if (
      championshipInput &&
      !championships?.some(
        (championship: GetInput) => championship.name === championshipInput
      )
    ) {
      addChampionshipMutation.mutate(championshipInput);
      toast({
        title: `Championnat ajouté`,
        description: `Le championnat ${championshipInput} a été ajouté`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setChampionshipInput("");
    }
  };

  const handleSelectChampionship = (championship: GetInput) => {
    setChampionshipInput(championship.name);
    setIsChampionshipOpen(false);
  };

  const handleDeleteChampionship = (championship: GetInput) => {
    deleteChampionshipMutation.mutate(championship._id);
    toast({
      title: `Championnat supprimé`,
      description: `Le championnat ${championship.name} a été supprimé`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setChampionshipInput("");
  };

  useEffect(() => {
    if (watchedChampionship && watchedChampionship.name) {
      setChampionshipInput(watchedChampionship.name);
    }
  }, [watchedChampionship]);

  // Grouping Championship related states and handlers
  const championshipState = {
    inputValue: championshipInput,
    isOpen: isChampionshipOpen,
    setIsOpen: setIsChampionshipOpen,
    handleInputChange: handleChampionshipInputChange,
    handleAdd: handleAddChampionship,
    handleSelect: handleSelectChampionship,
    handleDelete: handleDeleteChampionship,
  };

  //Category
  const [categoryInput, setCategoryInput] = useState("");
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);

  const {
    data: categories,
    isLoading: categoriesLoading,
    error: categoriesError,
  } = useQuery<GetInput[], Error>("categories", getCategory);

  const handleCategoryInputChange = (e: any) =>
    setCategoryInput(e.target.value);

  const addCategoryMutation = useMutation(addNewCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries("categories");
    },
  });

  const deleteCategoryMutation = useMutation(deleteCategory, {
    onSettled: () => {
      queryClient.invalidateQueries("categories");
    },
  });

  const handleAddCategory = () => {
    if (
      categoryInput &&
      !categories?.some((category: GetInput) => category.name === categoryInput)
    ) {
      addCategoryMutation.mutate(categoryInput);
      toast({
        title: `Catégorie ajoutée`,
        description: `La catégorie ${categoryInput} a été ajoutée`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setCategoryInput("");
    }
  };

  const handleSelectCategory = (category: GetInput) => {
    setCategoryInput(category.name);
    setIsCategoryOpen(false);
  };

  const handleDeleteCategory = (category: GetInput) => {
    deleteCategoryMutation.mutate(category._id);
    toast({
      title: `Catégorie supprimée`,
      description: `La catégorie ${category.name} a été supprimée`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setCategoryInput("");
  };

  useEffect(() => {
    if (watchedCategory && watchedCategory.name) {
      setCategoryInput(watchedCategory.name);
    }
  }, [watchedCategory]);

  // Grouping Category related states and handlers
  const categoryState = {
    inputValue: categoryInput,
    isOpen: isCategoryOpen,
    setIsOpen: setIsCategoryOpen,
    handleInputChange: handleCategoryInputChange,
    handleAdd: handleAddCategory,
    handleSelect: handleSelectCategory,
    handleDelete: handleDeleteCategory,
  };

  // Place
  const [placeInput, setPlaceInput] = useState<PlaceType>({
    name: "",
    address: {
      num: "",
      street: "",
      city: "",
      country: "",
      zip_code: "",
    },
  });
  const [isPlaceOpen, setIsPlaceOpen] = useState(true);

  const {
    data: places,
    isLoading: placesLoading,
    error: placesError,
  } = useQuery<PlaceType[], Error>("places", getPlaces);

  const addPlaceMutation = useMutation(addNewPlace, {
    onSuccess: () => {
      queryClient.invalidateQueries("places");
    },
  });

  const deletePlaceMutation = useMutation(deletePlace, {
    onSettled: () => {
      queryClient.invalidateQueries("places");
    },
  });

  const handlePlaceInputChange = (e: any) =>
    setPlaceInput({ ...placeInput, name: e.target.value });

  const handleAddPlace = (newPlace: PlaceType) => {
    addPlaceMutation.mutate(newPlace);
    toast({
      title: `Lieu ajouté`,
      description: `Le lieu ${newPlace.name} a été ajouté`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setPlaceInput({
      name: "",
      address: {
        num: "",
        street: "",
        city: "",
        country: "",
        zip_code: "",
      },
    });
  };

  const handleSelectPlace = (place: PlaceType) => {
    setPlaceInput(place);
    setIsPlaceOpen(false);
  };

  const handleDeletePlace = (place: PlaceType) => {
    if (place._id) {
      deletePlaceMutation.mutate(place._id);
      toast({
        title: `Lieu supprimé`,
        description: `Le lieu ${place.name} a été supprimé`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setPlaceInput({
        name: "",
        address: {
          num: "",
          street: "",
          city: "",
          country: "",
          zip_code: "",
        },
      });
    }
  };

  useEffect(() => {
    if (watchedPlace && watchedPlace.name) {
      setPlaceInput((prevState) => ({
        ...prevState,
        name: watchedPlace.name,
        address: watchedPlace.address,
      }));
    }
  }, [watchedPlace]);

  // Grouping Place related states and handlers
  const placeState = {
    inputValue: placeInput.name,
    isOpen: isPlaceOpen,
    setIsOpen: setIsPlaceOpen,
    handleInputChange: handlePlaceInputChange,
    handleAdd: handleAddPlace,
    handleSelect: handleSelectPlace,
    handleDelete: handleDeletePlace,
    options: places,
    onChange: setPlaceInput, // Vous pouvez adapter cette méthode si vous avez des besoins spécifiques
  };

  return (
    <>
      <Box my={4}>
        <Grid
          templateColumns={{ base: "1fr", md: "1fr", lg: "1fr" }}
          mt={4}
          gap={4}
        >
          <FormControl id="name_event">
            <FormLabel htmlFor="name_event">Nom</FormLabel>
            <Input {...register("name_event")} />
          </FormControl>

          <Box mt="4">
            <Controller
              name="category"
              render={({ field }) => (
                <CustomSelect
                  id="category"
                  label="Catégorie"
                  options={categories}
                  {...categoryState}
                  onChange={(category: any) => {
                    handleSelectCategory(category);
                    field.onChange(category);
                  }}
                />
              )}
            />
          </Box>

          {watchedCategory && watchedCategory.name === "Hors match" && (
            <Flex mt="4" align="center" justify="start">
              <Box>
                <Checkbox
                  {...register("internalCheckbox")}
                  colorScheme="yellow"
                  isChecked={internalCheckbox}
                >
                  Interne
                </Checkbox>
              </Box>
              <Box ml="4">
                <Checkbox
                  {...register("externalCheckbox")}
                  colorScheme="yellow"
                  isChecked={externalCheckbox}
                >
                  Externe
                </Checkbox>
              </Box>
            </Flex>
          )}

          {/* Lieu */}
          <Box>
            <Controller
              name="place"
              render={({ field }) => (
                <PlaceSelect
                  {...placeState}
                  label="Lieu"
                  id="place"
                  onChange={(place: PlaceType) => {
                    // Vous pouvez avoir une fonction handleSelectPlace
                    handleSelectPlace(place);
                    field.onChange(place);
                  }}
                />
              )}
            />
          </Box>

          {/* Team*/}
          <Controller
            name="home_team"
            render={({ field }) => (
              <CustomSelect
                id="home_team"
                label="Équipe domicile"
                options={teams}
                {...teamState}
                onChange={(team: any) => {
                  handleSelectTeam(team);
                  field.onChange(team);
                }}
              />
            )}
          />
          <Controller
            name="away_team"
            render={({ field }) => (
              <CustomSelect
                id="away_team"
                label="Équipe visiteuse"
                options={teams}
                {...AwayteamState}
                onChange={(team: any) => {
                  handleSelectAwayTeam(team);
                  field.onChange(team);
                }}
              />
            )}
          />

          <FormControl id="home_match">
            <FormLabel htmlFor="home_match">Match à domicile</FormLabel>
            <Controller
              name="home_match"
              defaultValue={true}
              render={({ field }) => (
                <Switch {...field} isChecked={field.value} />
              )}
            />
          </FormControl>
        </Grid>

        {/* Pour les champs de date */}
        <Grid
          templateColumns={{ base: "1fr", md: "1fr", lg: "1fr" }}
          mt={4}
          gap={4}
        >
          <FormControl id="day">
            <FormLabel htmlFor="day">Journée</FormLabel>
            <Input {...register("day")} />
          </FormControl>

          <FormControl id="date_event">
            <FormLabel>Date de l'évènement</FormLabel>
            <Controller
              name="date_event"
              render={({ field: { onChange, value, ...field } }) => (
                <Input
                  {...field}
                  type="date"
                  value={
                    value
                      ? new Date(value * 1000).toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(event) => {
                    const date = new Date(event.target.value);
                    onChange(date.getTime() / 1000);
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl id="end_event">
            <FormLabel>Fin de l'évènement</FormLabel>
            <Controller
              name="end_event"
              render={({ field: { onChange, value, ...field } }) => (
                <Input
                  {...field}
                  type="date"
                  value={
                    value
                      ? new Date(value * 1000).toISOString().split("T")[0]
                      : ""
                  }
                  onChange={(event) => {
                    const date = new Date(event.target.value);
                    onChange(date.getTime() / 1000);
                  }}
                />
              )}
            />
          </FormControl>

          <FormControl id="starting_match">
            <FormLabel>Début du match</FormLabel>
            <Controller
              name="starting_match"
              render={({ field: { onChange, value, ...field } }) => (
                <Input
                  {...field}
                  type="time"
                  value={
                    value
                      ? new Date((value + 3600) * 1000)
                          .toISOString()
                          .substring(11, 16) // Ajouter une heure ici pour la compensation
                      : ""
                  }
                  onChange={(event) => {
                    const [hours, minutes] = event.target.value
                      .split(":")
                      .map(Number);
                    const date = new Date(1970, 0, 1, hours, minutes); // Création d'une date sans fuseau horaire
                    onChange(date.getTime() / 1000);
                  }}
                />
              )}
            />
          </FormControl>

          <Box mt="4">
            <FormLabel>Description</FormLabel>
            <Controller
              name="description"
              render={({ field }) => (
                <ReactQuill
                  theme="snow"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Box>

          <Controller
            name="championship"
            render={({ field }) => (
              <CustomSelect
                id="championship"
                label="Championnat"
                options={championships}
                {...championshipState}
                onChange={(championship: any) => {
                  handleSelectChampionship(championship);
                  field.onChange(championship);
                }}
              />
            )}
          />

          <Controller
            name="season"
            render={({ field }) => (
              <CustomSelect
                id="season"
                label="Saison"
                options={seasons}
                {...seasonState}
                onChange={(season: any) => {
                  handleSelectSeason(season);
                  field.onChange(season);
                }}
              />
            )}
          />

          {/* Continuer avec les autres champs de date... */}
        </Grid>

        {/* Pour les booléens */}
      </Box>
      <Grid
        templateColumns={{ base: "1fr ", md: "1fr 1fr", lg: "1fr 1fr" }}
        mt={4}
        gap={4}
      >
        <FormControl id="analytic_code">
          <FormLabel htmlFor="analytic_code">Code analytique</FormLabel>
          <Input {...register("analytic_code")} />
        </FormControl>
        <FormControl id="financial_account">
          <FormLabel htmlFor="financial_account">
            Nom de la société responsable
          </FormLabel>
          <Input {...register("financial_account")} />
        </FormControl>
      </Grid>
    </>
  );
};
