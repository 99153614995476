import { AddIcon } from "@chakra-ui/icons";
import { Button, Flex, Image, Text, VStack } from "@chakra-ui/react";
import React from "react";

interface EmptyStateProps {
  onButtonClick?: () => void;
  imagePath: string;
  title: string;
  description: string;
  textButton?: string;
}

export const EmptyState: React.FC<EmptyStateProps> = ({
  onButtonClick,
  imagePath,
  title,
  description,
  textButton,
}) => {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py="6"
    >
      <VStack spacing={4} alignItems="center">
        <Image src={imagePath} boxSize="200px" />
        <Text fontSize="xl" fontWeight="medium">
          {title}
        </Text>
        <Text fontSize="md" textAlign="center" color="gray.500">
          {description}
        </Text>
        {onButtonClick && textButton && (
          <Button
            leftIcon={<AddIcon />}
            variant="primaryButton"
            onClick={onButtonClick}
          >
            {textButton}
          </Button>
        )}
      </VStack>
    </Flex>
  );
};
