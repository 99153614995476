import {
  Box,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Select,
  Stack,
} from "@chakra-ui/react";
import React, { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { AuthContext } from "../../../../utils/context";
import { Roles } from "../../../../utils/types/roles.interface";

const sizeShirt = ["XS", "S", "M", "L", "XL", "XXL"];
const sizePants = ["XS", "S", "M", "L", "XL", "XXL", "XXXL"];
const sizeVeste = ["32", "34", "36", "38", "40", "42", "44", "46", "48", "50"];

const InformationsForm: React.FC = () => {
  const {
    register,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext(); // utilise useFormContext pour obtenir les méthodes de formulaire
  const { userRole } = useContext(AuthContext); // Utilisez le contexte pour obtenir le rôle de l'utilisateur
  const someMatchValue = watch("some_match");
  const accreditationValue = watch("accreditation");
  const allSeasonValue = watch("all_season");
  const spokenLanguages = watch("spoken_languages");

  return (
    <Grid>
      <Box my={4}>
        {userRole === Roles.Admin && (
          <Grid
            templateColumns={{
              base: "1fr",
              md: "1fr 1fr 1fr",
              lg: "1fr 1fr 1fr",
            }}
            mt={4}
            gap={4}
          >
            <FormControl id="clothes.jacket_size">
              <FormLabel htmlFor="clothes.jacket_size">Taille Veste</FormLabel>
              <Select
                {...register("clothes.jacket_size")}
                placeholder="Sélectionner"
              >
                {sizeVeste.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl id="clothes.tshirt_size">
              <FormLabel htmlFor="clothes.tshirt_size">
                Taille T-Shirt
              </FormLabel>
              <Select
                {...register("clothes.tshirt_size")}
                placeholder="Sélectionner"
              >
                {sizeShirt.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Select>
            </FormControl>{" "}
            <FormControl id="clothes.pants_size">
              <FormLabel htmlFor="clothes.pants_size">
                Taille Pantalon
              </FormLabel>
              <Select
                {...register("clothes.pants_size")}
                placeholder="Sélectionner"
              >
                {sizePants.map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {userRole === Roles.Admin && (
          <Box>
            <FormLabel htmlFor="accreditation" mt="4">
              Accréditation
            </FormLabel>
            <Stack spacing="4" direction="row">
              <Checkbox
                {...register("accreditation")}
                colorScheme="yellow"
                isChecked={accreditationValue}
              >
                Oui
              </Checkbox>
            </Stack>
          </Box>
        )}
        <FormLabel htmlFor="spoken_languages" mt="4">
          Disponibilités
        </FormLabel>

        <Stack spacing="4" direction="row">
          <Checkbox
            {...register("some_match")}
            colorScheme="yellow"
            isChecked={someMatchValue}
          >
            Quelques matchs
          </Checkbox>
          <Checkbox
            {...register("all_season")}
            colorScheme="yellow"
            isChecked={allSeasonValue}
          >
            Toute la saison
          </Checkbox>
        </Stack>

        <FormControl id="spoken_languages" mt="4">
          <FormLabel htmlFor="spoken_languages">Langues parlées</FormLabel>
          <Stack spacing="4" direction="row">
            <Checkbox
              {...register("spoken_languages.french")}
              colorScheme="yellow"
              isChecked={spokenLanguages?.french}
            >
              Français
            </Checkbox>
            <Checkbox
              {...register("spoken_languages.english")}
              colorScheme="yellow"
              isChecked={spokenLanguages?.english}
            >
              Anglais
            </Checkbox>
            <Checkbox
              {...register("spoken_languages.spanish")}
              colorScheme="yellow"
              isChecked={spokenLanguages?.spanish}
            >
              Espagnol
            </Checkbox>
            <Checkbox
              {...register("spoken_languages.german")}
              colorScheme="yellow"
              isChecked={spokenLanguages?.german}
            >
              Allemand
            </Checkbox>
            <Input
              {...register("spoken_languages.custom")}
              placeholder="Autres langues"
              width="200px"
            />
          </Stack>
        </FormControl>
      </Box>
    </Grid>
  );
};

export default InformationsForm;
