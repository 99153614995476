import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Stack,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { MdCancel, MdCheckCircle } from "react-icons/md";
import { useMutation } from "react-query";
import { API_URL, API_VACATAIRE, PAYSLIPS } from "../../api";
import { CustomStepper } from "../_shared/interaction/Stepper";

interface FileSummaryComponentProps {
  validFiles: File[];
  invalidFiles: File[];
  currentStep: number;
  onReturn: () => void;
  onSuccess: () => void;
}

export const FileSummaryComponent: React.FC<FileSummaryComponentProps> = ({
  validFiles,
  invalidFiles,
  currentStep,
  onReturn,
  onSuccess,
}) => {
  const toast = useToast();
  const uploadMutation = useMutation(
    async (files: File[]) => {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });

      const response = await fetch(
        `${API_URL}${API_VACATAIRE}${PAYSLIPS}/upload`,
        {
          method: "POST",
          body: formData,
          credentials: "include",
        }
      );

      if (!response.ok) {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message || "Network response was not ok");
      }

      return response.json();
    },
    {
      onSuccess: (data) => {
        if (
          data.notFoundSerialNumbers &&
          data.notFoundSerialNumbers.length > 0
        ) {
          const notFoundMessage = `Matricules non trouvés: ${data.notFoundSerialNumbers.join(
            ", "
          )}`;
          toast({
            title: "Certains matricules n'ont pas été trouvés",
            description: notFoundMessage,
            status: "warning",
            duration: 8000,
            isClosable: true,
          });
        } else {
          onSuccess();
        }
      },
      onError: (error: Error) => {
        toast({
          title: "Erreur d'envoi",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      },
    }
  );

  const handleUpload = () => {
    uploadMutation.mutate(validFiles);
  };
  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 2 }}>
      <Box>
        <Stack>
          <Box py="12">
            <Flex justify="space-between" align="center" py="1">
              <Heading
                size={useBreakpointValue({ base: "xs", md: "sm" })}
                color="primary"
              >
                Récapitulatif des Fichiers
              </Heading>
            </Flex>
            <Text color="muted">
              Vérifiez les fichiers valides et invalides avant d'envoyer.
            </Text>
          </Box>

          <CustomStepper currentStep={currentStep} />
          <Flex
            direction={{ base: "column", md: "row" }}
            align="center"
            justify="space-evenly"
            w="100%"
            mb={4}
          >
            <Box w={{ base: "100%", md: "45%" }}>
              <p>Liste des fichiers valides :</p>
              <List>
                {validFiles.map((file, index) => (
                  <ListItem key={index} color="green.500">
                    <ListIcon as={MdCheckCircle} />
                    {file.name}
                  </ListItem>
                ))}
              </List>
            </Box>

            <Divider
              display={{ base: "none", md: "block" }}
              orientation="vertical"
              height="150px"
            />

            <Divider
              display={{ base: "block", md: "none" }}
              orientation="horizontal"
              my={4}
            />

            <Box w={{ base: "100%", md: "45%" }}>
              <p>Liste des fichiers invalides :</p>
              <List>
                {invalidFiles.map((file, index) => (
                  <ListItem key={index} color="red.500">
                    <ListIcon as={MdCancel} />
                    {file.name}
                  </ListItem>
                ))}
              </List>
            </Box>
          </Flex>
          <Flex justifyContent="space-between" mt={4}>
            <Button variant="outline" onClick={onReturn}>
              Retour
            </Button>
            <Button
              onClick={handleUpload}
              variant="primaryButton"
              isLoading={uploadMutation.isLoading}
            >
              Envoyer
            </Button>
          </Flex>
        </Stack>
      </Box>
    </Container>
  );
};
