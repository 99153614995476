// api paths
export const API_URL: string = process.env.REACT_APP_API_base_url as string;
export const API_LOGIN: string = process.env.REACT_APP_API_login as string;
export const API_LOGOUT: string = process.env.REACT_APP_API_logout as string;
export const API_SIGNUP: string = process.env.REACT_APP_API_signup as string;

export const API_USERS: string = process.env.REACT_APP_API_users as string;

export const API_CONFIRM_EMAIL: string = process.env
  .REACT_APP_API_confirm_email as string;
export const API_UPDATE_EMAIL: string = process.env
  .REACT_APP_API_update_email as string;
export const API_UPDATE_USERNAME: string = process.env
  .REACT_APP_API_update_infos as string;
export const API_ASK_RESET_PASSWORD: string = process.env
  .REACT_APP_API_ask_reset_password as string;
export const API_RESET_PASSWORD: string = process.env
  .REACT_APP_API_reset_password as string;
export const API_UPDATE_PASSWORD: string = process.env
  .REACT_APP_API_update_password_me as string;

export const API_VALIDATE: string = process.env
  .REACT_APP_API_validate as string;
export const API_VACATAIRE: string = process.env
  .REACT_APP_API_VACATAIRE as string;

export const API_RELANCE: string = process.env.REACT_APP_API_RELANCE as string;
export const REACT_APP_API_LISTING: string = process.env
  .REACT_APP_API_LISTING as string;

export const API_UPLOAD: string = process.env.REACT_APP_API_UPLOAD as string;

export const API_EVENEMENT: string = process.env
  .REACT_APP_API_EVENEMENT as string;

export const API_TEAMS: string = process.env.REACT_APP_API_TEAMS as string;
export const API_CATEGORY: string = process.env
  .REACT_APP_API_CATEGORY as string;
export const API_CHAMPIONSHIP: string = process.env
  .REACT_APP_API_CHAMPIONSHIP as string;
export const API_COOP: string = process.env.REACT_APP_API_COOP as string;
export const API_PLACE: string = process.env.REACT_APP_API_PLACE as string;
export const API_SEASONS: string = process.env.REACT_APP_API_SEASONS as string;
export const API_ARCHIVE: string = process.env.REACT_APP_API_ARCHIVE as string;
export const API_ACTIVE: string = process.env.REACT_APP_API_ACTIVE as string;
export const API_NOTIFICATION: string = process.env
  .REACT_APP_API_NOTIFICATION as string;

export const API_ME: string = process.env.REACT_APP_API_ME as string;
export const API_MAGIC_LINK: string = process.env
  .REACT_APP_API_MAGIC_LINK as string;
export const API_ZONES: string = process.env.REACT_APP_API_ZONES as string;
export const API_ZONESSECONDARY: string = process.env
  .REACT_APP_API_ZONESSECONDARY as string;
export const ZONEVACATAIRE: string = process.env
  .REACT_APP_API_ZONEVACATAIRE as string;
export const FILECONTRACT: string = process.env
  .REACT_APP_API_FILECONTRACT as string;
export const API_DRESSCODE: string = process.env
  .REACT_APP_API_DRESSCODE as string;

export const TEST_DPAE: string = process.env.REACT_APP_API_TESTDPAE as string;
export const PAYSLIPS: string = process.env.REACT_APP_API_PAYSLIPS as string;
