import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { ManageVacataire } from "./ManageVacataire";

export const TabComponents = () => (
  <Tabs size="md" variant="enclosed" color="primary">
    <TabList>
      <Tab fontWeight="bold">Salariés présents</Tab>
      <Tab fontWeight="bold">Salariés sortis</Tab>
    </TabList>
    <TabPanels>
      <TabPanel>
        <ManageVacataire archiveState={false} />
      </TabPanel>
      <TabPanel>
        {" "}
        <ManageVacataire archiveState={true} />
      </TabPanel>
    </TabPanels>
  </Tabs>
);
