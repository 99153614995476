import { createContext, useContext, useEffect, useState } from "react";

type Section = "profil" | "apartment";

interface SectionContextType {
  section: Section;
  setSection: (section: Section) => void;
}

const SectionContext = createContext<SectionContextType>({
  section: "apartment",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSection: () => {},
});

export function useSection() {
  return useContext(SectionContext);
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function SectionProvider(props: React.PropsWithChildren<{}>) {
  const [section, setSection] = useState<Section>(() => {
    const sectionFromStorage = localStorage.getItem("section");
    return sectionFromStorage ? (sectionFromStorage as Section) : "apartment";
  });

  useEffect(() => {
    localStorage.setItem("section", section);
  }, [section]);

  return (
    <SectionContext.Provider value={{ section, setSection }}>
      {props.children}
    </SectionContext.Provider>
  );
}
