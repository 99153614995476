import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { API_URL, TEST_DPAE, ZONEVACATAIRE } from "../../../api";
import { LoaderSpinner } from "../../_shared/loader/CustomSpinner";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  eventId: string | null;
  onDeleteSuccess: () => void;
};
export const DpaeModal: React.FC<Props> = ({
  isOpen,
  onClose: originalOnClose,
  eventId,
  onDeleteSuccess,
}) => {
  const [isTested, setIsTested] = useState(false);

  const [responseMessage, setResponseMessage] = useState(
    "Êtes-vous sûr de vouloir procéder à la génération des DPAE ? "
  );
  const [localDpaeResults, setLocalDpaeResults] = useState<any[] | null>(null);
  const fetchDpaeTestResults = async (
    eventId: string | null
  ): Promise<any[]> => {
    if (!eventId) throw new Error("No eventId provided");
    const response = await fetch(
      `${API_URL}${ZONEVACATAIRE}/${eventId}${TEST_DPAE}`,
      {
        credentials: "include",
      }
    );
    if (!response.ok) throw new Error("Network response was not ok");
    return response.json();
  };
  const { error, isLoading, refetch } = useQuery(
    "dpaeTestResults",
    () => fetchDpaeTestResults(eventId),
    {
      enabled: false,
      onSuccess: (data) => {
        setLocalDpaeResults(data);
      },
    }
  );

  useEffect(() => {
    if (!isOpen) {
      setLocalDpaeResults(null);
      setResponseMessage(
        "Êtes-vous sûr de vouloir procéder à la génération des DPAE ? "
      );
      setIsTested(false);
    }
  }, [isOpen]);

  const handleTestDPAE = () => {
    setResponseMessage("Résultat du test des DPAE :");
    refetch();
    setIsTested(true);
  };

  const onClose = () => {
    originalOnClose();
  };

  const handleGenerateDPAE = async () => {
    try {
      const response = await fetch(
        `${API_URL}${ZONEVACATAIRE}/${eventId}/submit-dpae`,
        {
          method: "POST",
          credentials: "include",
        }
      );

      if (!response.ok) throw new Error("Échec de la génération des DPAE");

      const responseData = await response.json();
      setResponseMessage("DPAE générées avec succès !");
      onDeleteSuccess();
    } catch (error) {
      console.error("Erreur lors de la génération des DPAE", error);
      setResponseMessage("Erreur lors de la génération des DPAE");
    }
  };

  const hasErrors =
    localDpaeResults?.some((vacataire) => vacataire.missingFields.length > 0) ||
    !isTested;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
        mx={{ base: 4, md: 15 }}
        maxW={{ base: "95vw", md: "35vw" }}
      >
        <ModalHeader>Génération des DPAE</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {responseMessage}
          {isLoading ? (
            <LoaderSpinner />
          ) : error ? (
            <Text color="red.500">
              Erreur lors de la récupération des données.
            </Text>
          ) : (
            <VStack align="start" spacing={4}>
              {localDpaeResults
                ?.filter((vacataire) => vacataire.missingFields.length > 0)
                .map((vacataire) => (
                  <Text key={vacataire.id}>
                    <strong>{`${vacataire.firstname} ${vacataire.name}`}</strong>
                    <span style={{ color: "red", marginLeft: "8px" }}>
                      : {vacataire.missingFields.join(", ")}
                    </span>
                  </Text>
                ))}
            </VStack>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            {" "}
            <Button colorScheme="gray" onClick={onClose}>
              Annuler
            </Button>
            <Button variant="secondaryButton" onClick={handleTestDPAE}>
              Tester les DPAE
            </Button>
            <Button
              variant="primaryButton"
              onClick={handleGenerateDPAE}
              isDisabled={hasErrors}
            >
              Générer les DPAE
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
