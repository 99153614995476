import * as Yup from "yup";

const currentDate = new Date();
const minTimestamp = new Date();
minTimestamp.setFullYear(currentDate.getFullYear() - 130);
const maxTimestamp = new Date();
maxTimestamp.setFullYear(currentDate.getFullYear() - 15);

const VacataireSchema = Yup.object().shape({
  _id: Yup.string(),
  serial_number: Yup.string(),
  name: Yup.string().required("Le nom est obligatoire."),
  firstname: Yup.string().required("Le prénom est obligatoire."),
  mail: Yup.string()
    .email("Veuillez entrer une adresse e-mail valide.")
    .required("L'email est obligatoire"),
  tel: Yup.string().test(
    "tel",
    "Veuillez entrer un numéro de téléphone valide pour la France. Ex: +33123456789",
    (value) => {
      if (!value || /^(0[1-9][0-9]{8})|(\+33[1-9][0-9]{8})$/.test(value)) {
        return true;
      }
      return false;
    }
  ),
  gender: Yup.string(),
  clothes: Yup.object({
    pants_size: Yup.string(),
    tshirt_size: Yup.string(),
    jacket_size: Yup.string(),
  }),
  address: Yup.object({
    num: Yup.number(),
    street: Yup.string(),
    zip_code: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
  }),
  birth: Yup.object({
    date_of_birth: Yup.number(),

    city: Yup.string(),
    country: Yup.string(),
  }),
  legal: Yup.boolean(),
  poste: Yup.string(),
  legal_resp: Yup.object({
    resp_name: Yup.string(),
    resp_firstname: Yup.string(),
    resp_tel: Yup.string(),
    resp_mail: Yup.string().email(),
  }),
  nationality: Yup.string(),
  security_number: Yup.string()
    .test(
      "is-num",
      "Le numéro de sécurité sociale doit être numérique",
      (value) => {
        if (value === undefined) {
          return true; // ou `return false` si vous voulez que le champ soit obligatoire
        }
        return /^[0-9]+$/.test(value);
      }
    )
    .matches(
      /^[0-9]{15}$/,
      "Le numéro de sécurité sociale doit avoir 15 chiffres exactement"
    )
    .max(15, "Le numéro de sécurité sociale ne peut pas dépasser 15 chiffres"),
  rib: Yup.string(),
  siap: Yup.boolean(),
  security: Yup.boolean(),
  iban: Yup.string()
    .nullable()
    .max(27, "L'IBAN ne peut pas dépasser 27 caractères"),

  bic: Yup.string()
    .nullable()
    .test("bic", "Le BIC fourni n'est pas valide, Ex: CMCIFR2B.", (value) => {
      if (!value || /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value)) {
        return true;
      }
      return false;
    })
    .max(11, "Le BIC ne peut pas dépasser 11 caractères, Ex: CMCIFR2B"),

  stranger: Yup.boolean(),
  legal_stranger: Yup.object({
    work_authorisation: Yup.boolean(),
    ets: Yup.boolean(),
    sector: Yup.boolean(),
  }),
  archived: Yup.boolean(),
  start_identity_num: Yup.number(),

  end_identity_num: Yup.number(),

  urgence_contact: Yup.object().shape({
    urgence_num: Yup.string().test(
      "urgence_num",
      "Veuillez entrer un numéro de téléphone valide pour la France. Ex: +33123456789",
      (value) => {
        if (!value || /^(0[1-9][0-9]{8})|(\+33[1-9][0-9]{8})$/.test(value)) {
          return true;
        }
        return false;
      }
    ),
  }),
});

export default VacataireSchema;
