import {
  Box,
  Button,
  Collapse,
  HStack,
  Icon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaWarehouse } from "react-icons/fa";
import { FiChevronDown } from "react-icons/fi";
import { getCategory } from "../../../../api/orga/Category";
import { CategoryType } from "../../../../utils/types/EventType";
import { useQuery } from "react-query";
import { EVENTS_PAGE } from "../../../../utils/navigation/endpoints";
import { useNavigate } from "react-router-dom";

export const EventsCollapse = () => {
  const navigate = useNavigate();

  const { data: categories } = useQuery<CategoryType[], Error>(
    "categories",
    getCategory,
  );

  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box>
      <Button
        variant="tertiary.accent"
        onClick={onToggle}
        onClickCapture={() => navigate(EVENTS_PAGE)}
        justifyContent="space-between"
        width="full"
      >
        <HStack spacing="3">
          <Icon as={FaWarehouse} />
          <Text as="span">Évènements</Text>
        </HStack>
        <PopoverIcon isOpen={isOpen} />
      </Button>
      <Collapse in={isOpen} animateOpacity>
        <Stack spacing="1" alignItems="stretch" ps="8" py="1">
          {(categories || []).map((category) => (
            <Button
              key={category.name}
              variant="tertiary.accent"
              justifyContent="start"
              onClick={() => navigate(`${EVENTS_PAGE}/${category.name}`)}
            >
              {category.name}
            </Button>
          ))}
        </Stack>
      </Collapse>
    </Box>
  );
};

export const PopoverIcon = (props: { isOpen: boolean }) => {
  const iconStyles = {
    transform: props.isOpen ? "rotate(-180deg)" : undefined,
    transition: "transform 0.2s",
    transformOrigin: "center",
  };
  return <Icon aria-hidden as={FiChevronDown} __css={iconStyles} />;
};
