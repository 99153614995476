import { LoginForm } from "../../components/Authentification/LoginForm";

export const Login = () => (
  <LoginForm
    px={{ base: "4", md: "8" }}
    py={{ base: "12", md: "48" }}
    width="full"
    maxW="md"
  />
);
