import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { EvenementType } from "../../../utils/types/EventType";
import EventSchema from "../../../utils/validation/ValidationEventSchema";
import { EventsFormField } from "./form/EventsFormField";

interface CreateEditEventProps {
  selectedEvent?: Partial<EvenementType> | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: EvenementType) => void;
}

const CreateEditEvent: React.FC<CreateEditEventProps> = ({
  selectedEvent,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const methods = useForm({
    resolver: yupResolver(EventSchema as any),
    defaultValues: selectedEvent || {}, // si selectedEvent est null, utiliser un objet vide
  });

  useEffect(() => {
    methods.reset(selectedEvent || {});
  }, [selectedEvent, methods]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        mx={{ base: 4, md: 15 }}
        maxW={{ base: "95vw", md: "50vw" }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {" "}
            <ModalHeader color="primary">
              {selectedEvent ? "Modifier l'évènement" : "Créer un évènement"}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <EventsFormField />
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                onClick={onClose}
                variant="outline"
                borderColor="red"
              >
                Annuler
              </Button>
              <Button type="submit" variant="primaryButton">
                {"Enregistrer"}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default CreateEditEvent;
