import React from "react";
import { AdminRoles, Roles } from "../types/roles.interface";

type AuthContextType = {
  isLoggedIn: boolean;
  token: string | null;
  user: any; // Ajoutez également un champ pour user
  userRole: Roles | AdminRoles | null; // Ajout du rôle utilisateur
  login: (token: string, user: any) => void; // Modifiez la signature de login pour accepter un utilisateur
  logout: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  isLoggedIn: true,
  token: null,
  user: null, // Ajoutez un champ pour user dans la valeur par défaut
  userRole: null, // Ajout de userRole à la valeur par défaut
  login: () => {},
  logout: () => {},
});
