import { ChevronDownIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import ExcelJS from "exceljs";
import { useContext, useState } from "react";
import { AuthContext } from "../../../utils/context";
import { PersonnelRolesLabels } from "../../../utils/types/EnumPost";
import { Roles } from "../../../utils/types/roles.interface";
import { STATUS_ACCEPT } from "./EvenementZones";

interface ZoneHeaderProps {
  eventDetails: any;
  eventId: string;
  service: string;
  zone: any;
  onDeleteZone: (
    eventId: string,
    zoneName: string,
    serviceName: string
  ) => void;
}

export const ZoneHeader: React.FC<ZoneHeaderProps> = ({
  eventDetails,
  eventId,
  service,
  zone,
  onDeleteZone,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { userRole } = useContext(AuthContext);

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);
  /*
  const downloadTable = () => {
    const data = zone.vacataires.map((vacataire: any) => ({
      NOM: vacataire.name,
      PRENOM: vacataire.firstname,
      POSTE: "", // A remplir selon votre data
      "HEURE DE CONVOCATION": "", // A remplir
      'HEURE D\'ARRIVEE OU MENTION "ABSENT"': "", // A remplir
      "SIGNATURE A L'ARRIVEE": "",
      "TEMPS DE PAUSE (non rémunéré)": "",
      "HEURE DE FIN": "", // Ajoutez l'heure de fin ici si vous l'avez
      "SIGNATURE AU DEPART": "",
      "PRESENT(E) EVENEMENT DU": "", // A remplir
    }));

    // Ajout du titre et des noms de colonnes
    const title = { NOM: `Nom de la zone: ${zone.name}` };
    const header = {
      NOM: "NOM",
      PRENOM: "PRENOM",
      POSTE: "POSTE",
      "HEURE DE CONVOCATION": "HEURE DE CONVOCATION",
      'HEURE D\'ARRIVEE OU MENTION "ABSENT"':
        'HEURE D\'ARRIVEE OU MENTION "ABSENT"',
      "SIGNATURE A L'ARRIVEE": "SIGNATURE A L'ARRIVEE",
      "TEMPS DE PAUSE (non rémunéré)": "TEMPS DE PAUSE (non rémunéré)",
      "HEURE DE FIN": "HEURE DE FIN",
      "SIGNATURE AU DEPART": "SIGNATURE AU DEPART",
      "PRESENT(E) EVENEMENT DU": "PRESENT(E) EVENEMENT DU",
    };

    data.unshift(header, title);

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

    // Réglage de la largeur des colonnes
    ws["!cols"] = [
      { wch: 20 },
      { wch: 20 },
      { wch: 15 },
      { wch: 25 },
      { wch: 35 },
      { wch: 25 },
      { wch: 30 },
      { wch: 20 },
      { wch: 25 },
      { wch: 25 },
    ];

    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ZoneSheet");
    XLSX.writeFile(wb, `${zone.name}.xlsx`);
  };
  */

  const generateXLSX = async () => {
    console.log(zone.vacataires);
    const vacatairesFiltres = zone.vacataires.filter(
      (vacataire: any) => vacataire.status === STATUS_ACCEPT
    );
    console.log(vacatairesFiltres);

    const workbook = new ExcelJS.Workbook();

    const worksheet = workbook.addWorksheet("Feuille de Margement");

    const headerData = [
      ["Événement", eventDetails?.name_event],
      [
        "Date",
        eventDetails?.date_event
          ? new Date(eventDetails.date_event * 1000).toLocaleDateString(
              "fr-FR",
              {
                weekday: "long",
                day: "2-digit",
                month: "long",
                year: "numeric",
              }
            )
          : "Aucune date d'évènement spécifiée",
      ],
      [
        "Lieu",
        eventDetails?.place
          ? eventDetails.place.name
          : "Aucune adresse d'évènement spécifiée",
      ],
      ["Service", service],
      ["Zone", zone?.name ? zone.name : "Aucune zone spécifiée"],
      [
        "Référence",
        eventDetails?.analytic_code
          ? eventDetails.analytic_code
          : "Aucun code analytique spécifiée",
      ],
      ["id", eventId],
    ];

    headerData.forEach((row, rowIndex) => {
      row.forEach((cell, cellIndex) => {
        const excelCell = worksheet.getCell(rowIndex + 1, cellIndex + 1);
        excelCell.value = cell;
        if (cellIndex === 0) {
          // Si c'est une cellule d'en-tête
          excelCell.font = { color: { argb: "FFFF0000" } }; // Rouge
        } else {
          excelCell.font = { bold: true };
        }
      });
    });

    // Ajout d'une ligne vide pour l'espace
    const spacerRowIndex = headerData.length + 1;
    worksheet.addRow([]);

    let mappedVacataires;

    if (vacatairesFiltres && vacatairesFiltres.length > 0) {
      mappedVacataires = vacatairesFiltres.map((vacataire: any) => {
        // Conversion du timestamp en heure française
        const convertTimestampToFrenchHour = (timestamp: number) => {
          const date = new Date(timestamp * 1000); // Multiplier par 1000 car on suppose que le timestamp est en secondes
          return date.toLocaleTimeString("fr-FR", {
            hour: "2-digit",
            minute: "2-digit",
          });
        };

        const startHourFrench = vacataire.startHour
          ? convertTimestampToFrenchHour(vacataire.startHour)
          : "Non renseigné";
        const roleLabel =
          PersonnelRolesLabels[
            vacataire.fonction as keyof typeof PersonnelRolesLabels
          ] || "Non renseigné";

        return [
          vacataire.name || "Non renseignée",
          vacataire.firstname || "Non renseigné",
          roleLabel || "Non renseigné",
          startHourFrench,
          "",
          "",
          "",
          "",
          "",
          "",
          "",
        ];
      });
    } else {
      mappedVacataires = [["Aucun vacataire défini pour cette zone"]];
    }

    const data = [
      [
        "NOM",
        "PRENOM",
        "POSTE",
        "HEURE DE CONVOCATION",
        "HEURE REELLE D'ARRIVEE",
        "SIGNATURE À L'ARRIVEE",
        "TEMPS DE PAUSE (non rémunéré)",
        "HEURE DE FIN",
        "SIGNATURE AU DEPART",
        "NOMBRE D'HEURES TOTAL EFFECTUÉES",
      ],
      ...mappedVacataires,
    ];

    data.forEach((row, rowIndex) => {
      row.forEach(
        (
          cell:
            | string
            | number
            | boolean
            | Date
            | ExcelJS.CellErrorValue
            | ExcelJS.CellRichTextValue
            | ExcelJS.CellHyperlinkValue
            | ExcelJS.CellFormulaValue
            | ExcelJS.CellSharedFormulaValue
            | null
            | undefined,
          cellIndex: number
        ) => {
          const excelCell = worksheet.getCell(
            rowIndex + spacerRowIndex + 1,
            cellIndex + 1
          );
          excelCell.value = cell;

          if (rowIndex === 0) {
            excelCell.fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: "FFD3D3D3" }, // Gris clair
            };
          }
        }
      );
    });

    // Largeur des colonnes pour les en-têtes et leurs valeurs
    worksheet.columns = [
      { width: 20 },
      { width: 30 },
      { width: 50 },
      { width: 20 },
      { width: 20 },
      { width: 20 },
      { width: 35 },
      { width: 20 },
      { width: 20 },
      { width: 35 },
      { width: 40 },
    ];

    // Sauvegardez le fichier
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const fileName = `${eventDetails?.name_event || "unknownEvent"}_${
      service || "unknownService"
    }_${zone?.name || "unknownZone"}_emargement.xlsx`;

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <Heading as="h2" size="xs" color="secondary">
        {zone.name}
      </Heading>

      <Menu>
        <MenuButton
          as={Button}
          color="primary"
          rightIcon={<ChevronDownIcon />}
          isDisabled={userRole == Roles.LimitedAdmin}
        />
        <MenuList>
          <MenuItem
            icon={<DownloadIcon />}
            onClick={generateXLSX}
            style={{ color: "green" }}
          >
            Télécharger le tableau
          </MenuItem>
          {userRole != Roles.LimitedAdmin && (
            <MenuItem
              color={"red"}
              icon={<DeleteIcon color="red" />}
              onClick={handleOpenModal}
            >
              Supprimer
            </MenuItem>
          )}
        </MenuList>
      </Menu>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirmer la suppression</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Êtes-vous sûr de vouloir supprimer cette zone ?</ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={handleCloseModal}>
              Annuler
            </Button>
            <Button
              colorScheme="red"
              ml={3}
              onClick={() => {
                onDeleteZone(eventId, zone.name, service);
                handleCloseModal();
              }}
            >
              Confirmer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
