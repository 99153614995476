// TOKEN & COOKIES
import jwtDecode, { JwtPayload } from "jwt-decode";
import Cookies from "universal-cookie";

// API
import { LoginType, ToLoginType } from "../../utils/types";
import { loginForm, logoutForm } from "./Users";

// COOKIE NAME
const ccCookieName = "eventsid";

/****************************/
/** AUTHENTICATION HELPERS **/
/****************************/

/**
 * This reads the cookie "eventsid" to gather the token
 * @returns the token as a string or null if not found
 */
const getTokenFromCookie = () => {
  const cookies = new Cookies();
  const token = cookies.get(ccCookieName);
  return token;
};

/**
 * This removes the cookie "eventsid"
 */
const removeCookie = () => {
  const cookies = new Cookies();
  cookies.remove(ccCookieName);
};

/**
 * Clear local storage for stored token and user info
 */
const clearLocalStorage = () => {
  localStorage.removeItem("eventsid");
  localStorage.removeItem("user");
};

/**
 * Check the localStorage for the token and if it doesn't find it there, it checks the cookie.
 * @returns true if user is authenticated, false otherwise
 */
const isAuthenticated = (): boolean => {
  let token = localStorage.getItem("eventsid");
  if (token === undefined || token === null || token === "") {
    // gathering the JWT from the cookie
    token = getTokenFromCookie();
    if (token === undefined || token === null || token === "") return false;
    localStorage.setItem("eventsid", token);
  }
  // we found the token so lets check it
  if (checkToken(token)) {
    // Token is valid
    return true;
  } else {
    // Token is invalid
    clearLocalStorage();
    return false;
  }
};

/**
 * Checks the token validity and expiration
 * @returns true if valid and unexpired, false otherwise
 */
const checkToken = (token: string) => {
  try {
    const decodedJwt = jwtDecode<JwtPayload>(token);
    if (decodedJwt === null) {
      return false;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (decodedJwt.exp! * 1000 < Date.now()) {
      return false;
    } else return true;
  } catch (error) {
    console.error("checkToken: " + error);
    return false;
  }
};

/**
 * Login Function, called with a user identifies himself.
 * It calls the API with the credentials and, if there is an answer :
 * - set the user info in the local storage
 * - set the token in the local storage
 * @param email valid email string, will be used as main identifier
 * @param password
 */

const login = async (
  data: ToLoginType,
  login: (newToken: string, newUser: any) => void
): Promise<LoginType> => {
  const loginInfo = await loginForm(data);
  login(loginInfo.token, loginInfo.user);
  return loginInfo;
};
/**
 * Logout function:
 * - user is logged out from the api
 * - cookie is removed
 * - clean the local storage
 */
const logout = async (): Promise<void> => {
  try {
    console.log("!a va logout");
    await logoutForm();
  } catch (error) {
    console.log(error);
  } finally {
    clearLocalStorage();
    removeCookie();
  }
};

/**
 * When we receive a 401, we have to log the user out ; so we just clear local data.
 */
const logout401 = () => {
  logout();
  console.error("Automatic logout due to the reception of a 401");
};

/**
 * @returns the user name stored in the local storage
 */
const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user") as string);
};

const AuthService = {
  login,
  logout,
  logout401,
  getCurrentUser,
  isAuthenticated,
};

export default AuthService;
