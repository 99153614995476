import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useRef } from "react";
import { Vacataire } from "../../../utils/types";

interface ArchiveVacataireModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  vacataire: Vacataire | undefined;
}
export const ArchiveVacataireModal: React.FC<ArchiveVacataireModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  vacataire,
}) => {
  const cancelRef = useRef();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx={{ base: 4, md: 15 }}>
        <ModalHeader fontSize="lg" fontWeight="bold" color="primary">
          {vacataire?.archived ? "Restaurer" : "Archiver"} le vacataire
        </ModalHeader>

        <ModalBody>
          Êtes-vous sûr de vouloir{" "}
          {vacataire?.archived ? "restaurer" : "archiver"}{" "}
          {vacataire?.firstname} {vacataire?.name} ?
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Annuler</Button>
          <Button
            colorScheme={vacataire?.archived ? "green" : "red"}
            onClick={onConfirm}
            ml={3}
          >
            {vacataire?.archived ? "Restaurer" : "Archiver"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
