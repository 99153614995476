import { Box } from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ContentContainerProps {
  children: ReactNode;
  fullWidth?: boolean;
}

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  fullWidth = false,
}) => {
  return (
    <Box flex="1" my={4} mx={fullWidth ? 2 : 4}>
      {children}
    </Box>
  );
};

export default ContentContainer;
