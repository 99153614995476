import {
  ArrowDownIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  Icon,
  SearchIcon,
} from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Stack,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";

import { ChildCare } from "@mui/icons-material";
import React, { useContext, useState } from "react";
import {
  FaBirthdayCake,
  FaEnvelope,
  FaPhone,
  FaRedo,
  FaUserCircle,
} from "react-icons/fa";
import { FiArchive, FiEdit, FiFilePlus } from "react-icons/fi";
import { API_URL, API_VACATAIRE } from "../../api";
import { AuthContext } from "../../utils/context";
import { Vacataire } from "../../utils/types";
import { Roles } from "../../utils/types/roles.interface";

interface VacataireTableProps extends TableProps {
  data: Vacataire[];
  openArchiveModal: (vacataire: Vacataire) => void;
  openEditVacataire: (vacataire: Vacataire, initialTabIndex?: number) => void;
  openRelanceMail: (vacataire: Vacataire) => void;

  isArchiveTab: boolean;
}
export const VacataireTable: React.FC<VacataireTableProps> = ({
  // onOpenEdit,
  data,
  isArchiveTab,
  openArchiveModal,
  openEditVacataire,
  openRelanceMail,
  ...props
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [matriculeFilter, setMatriculeFilter] = useState<
    "all" | "defined" | "undefined"
  >("all");
  const { userRole } = useContext(AuthContext);

  const [sortConfig, setSortConfig] = useState<{
    key: keyof Vacataire;
    direction: "ascending" | "descending";
  } | null>(null);

  const isBirthday = (timestamp: number) => {
    if (timestamp === undefined) return false;

    const birthday = new Date(timestamp * 1000); // Convertit le timestamp en millisecondes
    const today = new Date();
    return (
      birthday.getDate() === today.getDate() &&
      birthday.getMonth() === today.getMonth()
    );
  };

  const sortedData = React.useMemo(() => {
    let sortableData = [...data];
    if (sortConfig !== null) {
      sortableData.sort((a, b) => {
        const key = sortConfig.key;

        if (key === "availability") {
          const aAvailable = a.all_season || a.some_match;
          const bAvailable = b.all_season || b.some_match;
          return aAvailable === bAvailable
            ? 0
            : aAvailable
            ? sortConfig.direction === "ascending"
              ? 1
              : -1
            : sortConfig.direction === "ascending"
            ? -1
            : 1;
        }
        if (typeof a[key] === "string" && typeof b[key] === "string") {
          const aValue = a[key] as string;
          const bValue = b[key] as string;
          return sortConfig.direction === "ascending"
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue);
        } else if (typeof a[key] === "boolean" && typeof b[key] === "boolean") {
          const aValue = a[key] as boolean;
          const bValue = b[key] as boolean;
          return aValue === bValue
            ? 0
            : aValue
            ? sortConfig.direction === "ascending"
              ? 1
              : -1
            : sortConfig.direction === "ascending"
            ? -1
            : 1;
        } else if (typeof a[key] === "number" && typeof b[key] === "number") {
          const aValue = a[key] as number;
          const bValue = b[key] as number;
          return sortConfig.direction === "ascending"
            ? aValue - bValue
            : bValue - aValue;
        } else {
          return 0;
        }
      });
    }
    return sortableData;
  }, [data, sortConfig]);

  const filteredData = React.useMemo(() => {
    return sortedData.filter((data) =>
      `${data.firstname} ${data.name} ${data.serial_number}` // Inclure le serial_number ici
        .toLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  }, [searchTerm, sortedData]);

  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;

  const nextPage = () => {
    if (currentPage < filteredData.length / pageSize - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginatedData = React.useMemo(() => {
    return filteredData.slice(
      currentPage * pageSize,
      (currentPage + 1) * pageSize
    );
  }, [filteredData, currentPage, pageSize]);

  const isLastPage = currentPage >= Math.ceil(data.length / pageSize) - 1;

  const requestSort = (key: keyof Vacataire) => {
    let direction: "ascending" | "descending" = "ascending";
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === "ascending"
    ) {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  React.useEffect(() => {
    setCurrentPage(0);
  }, [searchTerm]);
  return (
    <Box >
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        <Input
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder="Rechercher par nom, prénom et matricule ..."
        />
      </InputGroup>

      <Table {...props}>
        <Thead>
          <Tr>
            <Th></Th>
            <Th
              onClick={() => requestSort("serial_number")}
              style={{ cursor: "pointer" }}
            >
              Matricule
              {sortConfig?.key === "serial_number" ? (
                sortConfig.direction === "ascending" ? (
                  <ArrowUpIcon />
                ) : (
                  <ArrowDownIcon />
                )
              ) : (
                <ArrowUpDownIcon />
              )}
            </Th>
            <Th
              onClick={() => requestSort("name")}
              style={{ cursor: "pointer" }}
            >
              Nom
              {sortConfig?.key === "name" ? (
                sortConfig.direction === "ascending" ? (
                  <ArrowUpIcon />
                ) : (
                  <ArrowDownIcon />
                )
              ) : (
                <ArrowUpDownIcon />
              )}
            </Th>
            <Th>Email</Th>
            <Th>Téléphone</Th>
            <Th>Naissance</Th>
            <Th
              onClick={() => requestSort("availability")}
              style={{ cursor: "pointer" }}
            >
              Disponibilité
              {sortConfig?.key === "availability" ? (
                sortConfig.direction === "ascending" ? (
                  <ArrowUpIcon />
                ) : (
                  <ArrowDownIcon />
                )
              ) : (
                <ArrowUpDownIcon />
              )}
            </Th>
            {userRole !== Roles.LimitedAdmin && (
              <>
                <Th
                  onClick={() => requestSort("profileComplete")}
                  style={{ cursor: "pointer" }}
                >
                  Status
                  {sortConfig?.key === "profileComplete" ? (
                    sortConfig.direction === "ascending" ? (
                      <ArrowUpIcon />
                    ) : (
                      <ArrowDownIcon />
                    )
                  ) : (
                    <ArrowUpDownIcon />
                  )}
                </Th>
                <Th>Actions</Th>
              </>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {paginatedData.map((data) => (
            <Tr key={data.id}>
              <Td>
                {data.photo ? (
                  <Image
                    src={`${API_URL}${API_VACATAIRE}/download/${data._id}/photo`}
                    alt="Photo du vacataire"
                    boxSize="50px"
                    maxWidth="100px"
                    objectFit="cover"
                    borderRadius="md"
                  />
                ) : (
                  <FaUserCircle size="40px" color="gray.500" />
                )}
              </Td>
              <Td>{data.serial_number}</Td>

              <Td>
                <Box display="flex" alignItems="center">
                  {data.accreditation && (
                    <Badge style={{ marginRight: "8px" }} />
                  )}
                  {data.isMinor && <ChildCare style={{ marginRight: "8px" }} />}
                  <Text fontWeight="medium">{`${data.firstname} ${data.name}`}</Text>
                </Box>
              </Td>
              <Td>
                <Box>
                  {data.mail && (
                    <Link href={`mailto:${data.mail}`}>
                      <Stack direction="row" align="center" color="medium">
                        <FaEnvelope />
                        <Text>{data.mail}</Text>
                      </Stack>
                    </Link>
                  )}
                </Box>
              </Td>
              <Td>
                {data.tel && (
                  <Box>
                    <Link href={`tel:${data.tel}`}>
                      <Stack direction="row" align="center" color="medium">
                        <FaPhone />
                        <Text>{data.tel}</Text>
                      </Stack>
                    </Link>
                  </Box>
                )}
              </Td>
              <Td>
                {data.birth?.date_of_birth &&
                  isBirthday(data.birth.date_of_birth) && (
                    <Icon as={FaBirthdayCake} color="yellow.400" mr="2" />
                  )}
                {data.birth?.date_of_birth &&
                  new Date(
                    data.birth.date_of_birth * 1000
                  ).toLocaleDateString()}{" "}
                {/* Convertit et affiche la date */}
              </Td>
              <Td>
                <Badge
                  colorScheme={
                    data.some_match || data.all_season ? "green" : "red"
                  }
                >
                  {data.some_match && "Quelques matchs"}
                  {data.all_season && "Toute la saison"}
                </Badge>
              </Td>
              {userRole !== Roles.LimitedAdmin && (
                <>
                  <Td>
                    <Badge colorScheme={data.profileComplete ? "green" : "red"}>
                      {data.profileComplete
                        ? "Profil à jour"
                        : "Profil invalide"}
                    </Badge>
                  </Td>
                  <Td>
                    <HStack spacing="1">
                      <Tooltip label="Relancer le vacataire">
                        <IconButton
                          id="deposit"
                          colorScheme="blue"
                          icon={<FaRedo fontSize="1.25rem" />}
                          variant="ghost"
                          aria-label="Relancer "
                          onClick={() => openRelanceMail(data)}
                        />
                      </Tooltip>
                      <Tooltip label="Déposer un document">
                        <IconButton
                          id="deposit"
                          color="secondary"
                          icon={<FiFilePlus fontSize="1.25rem" />}
                          variant="ghost"
                          aria-label="Deposit member"
                          onClick={() => openEditVacataire(data, 2)} // 2 est l'index de l'onglet "Dépôt de documents"
                        />
                      </Tooltip>
                      <Tooltip label="Modifier le membre">
                        <IconButton
                          id="edit"
                          color="blue"
                          onClick={() => openEditVacataire(data, 0)}
                          icon={<FiEdit fontSize="1.25rem" />}
                          variant="ghost"
                          aria-label="Edit member"
                          // onClick={() => onOpenEdit(data)}
                        />
                      </Tooltip>

                      {isArchiveTab ? (
                        <Tooltip label="Restaurer le membre">
                          <IconButton
                            id="restore"
                            color="green"
                            onClick={() => openArchiveModal(data)}
                            icon={<FiArchive fontSize="1.25rem" />}
                            variant="ghost"
                            aria-label="Restore member"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip label="Archiver le membre">
                          <IconButton
                            id="archive"
                            color="red"
                            onClick={() => openArchiveModal(data)}
                            icon={<FiArchive fontSize="1.25rem" />}
                            variant="ghost"
                            aria-label="Archive member"
                          />
                        </Tooltip>
                      )}
                    </HStack>
                  </Td>
                </>
              )}
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justifyContent="flex-end" mt="4">
        <IconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon />}
          onClick={prevPage}
          disabled={currentPage === 0}
          mr="4"
        />
        {!isLastPage && (
          <IconButton
            aria-label="Next page"
            icon={<ChevronRightIcon />}
            onClick={nextPage}
          />
        )}
      </Flex>
    </Box>
  );
};
