import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface NewDressCodeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (name: string, description: string) => void;
  name: string;
}

const NewDressCodeModal: React.FC<NewDressCodeModalProps> = ({
  isOpen,
  onClose,
  onSave,
  name,
}) => {
  const [description, setDescription] = useState("");
  const [localName, setLocalName] = useState(name); // Ajoutez une variable d'état locale

  useEffect(() => {
    setLocalName(name);
  }, [name]);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalName(e.target.value);
  };

  const handleSave = () => {
    onSave(localName, description);
    setLocalName(""); // Réinitialise le nom
    setDescription(""); // Réinitialise la description
    onClose(); // Ferme la modal
  };

  const handleClose = () => {
    setLocalName(""); // Réinitialise le nom à la fermeture de la modal
    setDescription(""); // Réinitialise la description à la fermeture de la modal
    onClose(); // Ferme la modal
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Créer un nouveau DressCode</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl mt={4}>
            <FormLabel>Nom</FormLabel>
            <Input value={localName} onChange={handleNameChange} />
          </FormControl>
          <FormControl mt={4} isRequired={true}>
            <FormLabel>Description</FormLabel>
            <Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} mr={3}>
            Annuler
          </Button>
          <Button
            variant={
              localName.trim() === "" || description.trim() === ""
                ? "outline"
                : "primaryButton"
            }
            onClick={handleSave}
            isDisabled={localName.trim() === "" || description.trim() === ""}
          >
            Enregistrer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewDressCodeModal;
