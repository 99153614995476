import { API_CATEGORY, API_URL } from "../ApiPaths";

/** TEAM */
export const getCategory = async () => {
  const res = await fetch(API_URL + API_CATEGORY, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};

export const addNewCategory = async (tmpNew: string) => {
  const res = await fetch(API_URL + API_CATEGORY, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: tmpNew }),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return res.json();
};

export const deleteCategory = async (id: string) => {
  const res = await fetch(`${API_URL}${API_CATEGORY}/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return res.json();
};
