import { API_DRESSCODE, API_URL } from "../ApiPaths";

/** TEAM */
export const getDressCode = async () => {
  const res = await fetch(API_URL + API_DRESSCODE, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};

export const addNewDressCode = async (name: string, description: string) => {
  const res = await fetch(API_URL + API_DRESSCODE, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: name, description: description }), // Ajoutez la description ici
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return res.json();
};

export const deleteDressCode = async (id: string) => {
  const res = await fetch(`${API_URL}${API_DRESSCODE}/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return res.json();
};
