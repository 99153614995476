import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  IconButton,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaCalendarAlt, FaUser } from "react-icons/fa";
import { NotificationType } from "../../../utils/types/NotificationType";

interface NotificationTableProps {
  data: NotificationType[];
}

export const NotificationTable = ({ data }: NotificationTableProps) => {
  const [currentPage, setCurrentPage] = useState(0);
  const pageSize = 10;

  const formatDate = (dateString: Date) => {
    const date = new Date(dateString);
    const optionsDate: Intl.DateTimeFormatOptions = {
      weekday: "long",
      day: "numeric",
      month: "long",
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
    };
    const formattedDate = date.toLocaleDateString("fr-FR", optionsDate);
    const formattedTime = date.toLocaleTimeString("fr-FR", optionsTime);
    return `${formattedDate} ${formattedTime}`;
  };

  const nextPage = () => {
    if (currentPage < data.length / pageSize - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const paginatedData = data.slice(
    currentPage * pageSize,
    (currentPage + 1) * pageSize
  );
  const renderIcons = (title: string) => {
    const lowerCaseTitle = title.toLowerCase();
    const hasVacataire = lowerCaseTitle.includes("vacataire");
    const hasEvent = lowerCaseTitle.includes("événement");

    return (
      <Flex align="center">
        {hasVacataire && (
          <FaUser color="#002857" style={{ marginRight: "8px" }} />
        )}
        {hasEvent && <FaCalendarAlt color="#C5AA68" />}
      </Flex>
    );
  };

  return (
    <Box overflowX="auto">
      <Table
        textAlign="left"
        mt="32px"
        width="full"
        borderWidth={1}
        borderColor="grey.200"
      >
        <Thead bg="gray.50">
          <Tr>
            <Th color="gray.500" fontSize="xs" textTransform="uppercase"></Th>
            <Th color="gray.500" fontSize="xs" textTransform="uppercase">
              Utilisateur
            </Th>
            <Th color="gray.500" fontSize="xs" textTransform="uppercase">
              Titre
            </Th>
            <Th color="gray.500" fontSize="xs" textTransform="uppercase">
              Description
            </Th>
            <Th color="gray.500" fontSize="xs" textTransform="uppercase">
              Date
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {paginatedData.map((notification, i) => (
            <Tr key={i}>
              <Td>{renderIcons(notification.title)}</Td>
              <Td>
                <Text fontWeight="bold">{notification.userId.email}</Text>
              </Td>
              <Td>
                <Text fontWeight="bold">{notification.title}</Text>
              </Td>
              <Td>
                <Text>{notification.description}</Text>
              </Td>
              <Td>
                <Text>{formatDate(notification.createdAt)}</Text>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Flex justifyContent="flex-end" mt="4">
        <IconButton
          aria-label="Previous page"
          icon={<ChevronLeftIcon />}
          onClick={prevPage}
          disabled={currentPage === 0}
          mr="4"
        />
        <IconButton
          aria-label="Next page"
          icon={<ChevronRightIcon />}
          onClick={nextPage}
          disabled={currentPage >= data.length / pageSize - 1}
        />
      </Flex>
    </Box>
  );
};
