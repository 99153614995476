import {
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";

export const HelpView = () => {
  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 2 }}>
      <Box>
        <Stack>
          <Box>
            <Box py="12">
              <Flex justify="space-between" align="center" py="1">
                <Heading
                  size={useBreakpointValue({ base: "xs", md: "sm" })}
                  color="primary"
                >
                  Aide
                </Heading>
              </Flex>
              <Text color="muted">
                Bienvenue dans l'aide de l'application. Vous trouverez
                ci-dessous des informations sur différentes parties de
                l'application.
              </Text>
            </Box>

            <Box
              boxShadow={{ base: "xlg", md: "xlg" }}
              borderRadius={{ base: "none", md: "lg" }}
            >
              <Stack spacing="5">
                {/* Section Comment fonctionne la partie vacataire */}
                <Box>
                  <Heading size="md" color="primary">
                    Comment fonctionne la partie vacataire
                  </Heading>
                  <Text>
                    Ici, vous pouvez gérer vos vacataires. Vous pouvez créer de
                    nouveaux vacataires, les modifier, les archiver ou les
                    restaurer. Vous pouvez également afficher une liste de tous
                    les vacataires ou filtrer les vacataires archivés.
                  </Text>
                </Box>

                {/* Section Comment fonctionne la partie événement */}
                <Box>
                  <Heading size="md" color="primary">
                    Comment fonctionne la partie évènement
                  </Heading>
                  <Text>
                    La partie évènement vous permet de gérer vos évènements.
                    Vous pouvez créer de nouveaux évènements, les modifier, les
                    supprimer ou les afficher dans une liste. Chaque évènement
                    contient des informations telles que le nom de l'évènement,
                    les équipes participantes, la date et l'heure, la
                    description, etc.
                  </Text>
                </Box>

                {/* Section Comment fonctionne la partie listing vacataires */}
                <Box>
                  <Heading size="md" color="primary">
                    Comment fonctionne la partie listing vacataires
                  </Heading>
                  <Text>
                    La partie listing vacataires affiche la liste de tous les
                    vacataires enregistrés. Vous pouvez trier, filtrer et
                    rechercher des vacataires spécifiques. Vous pouvez également
                    effectuer des actions sur les vacataires tels que
                    l'archivage, la modification et la visualisation des
                    détails.
                  </Text>
                </Box>

                {/* Section Comment fonctionne la partie espace de dépôt */}
                <Box>
                  <Heading size="md" color="primary">
                    Comment fonctionne la partie espace de dépôt
                  </Heading>
                  <Text>
                    L'espace de dépôt est un endroit où vous pouvez stocker et
                    gérer vos documents importants. Vous pouvez télécharger des
                    documents, les organiser dans des dossiers et effectuer des
                    opérations telles que la suppression et le déplacement des
                    documents.
                  </Text>
                </Box>
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
