import * as Yup from "yup";

const TeamSchema = Yup.object().shape({
  _id: Yup.string(),
  name: Yup.string(),
  // ajoutez d'autres champs si nécessaire
});

const EventSchema = Yup.object().shape({
  _id: Yup.string(),
  name_event: Yup.string().required("Le nom est obligatoire."),
  in_progress: Yup.boolean(),
  home_team: TeamSchema,
  away_team: TeamSchema,
  home_match: Yup.boolean(),
  day: Yup.string(),
  date_event: Yup.number(),
  end_event: Yup.number(),
  starting_match: Yup.number(),
  description: Yup.string(),
  category: Yup.object().shape({
    _id: Yup.string(),
    name: Yup.string(),
  }),
  championship: Yup.object().shape({
    _id: Yup.string(),
    name: Yup.string(),
  }),
  season: Yup.object().shape({
    _id: Yup.string(),
    name: Yup.string(),
  }),
  place: Yup.object().shape({
    _id: Yup.string(),
    name: Yup.string(),
    num: Yup.number(),
    street: Yup.string(),
    zip_code: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
  }),
  start_invit: Yup.number(),
  end_invit: Yup.number(),
  financial_account: Yup.string(),
  analytic_code: Yup.string(),
});

export default EventSchema;
