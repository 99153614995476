export default function debounce<F extends (...args: any[]) => any>(
  func: F,
  wait: number
): (...args: Parameters<F>) => void {
  let timeout: NodeJS.Timeout | null;
  return (...args: Parameters<F>) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func(...args);
    }, wait);
  };
}

export function throttle<F extends (args: any[]) => any>(
  func: F,
  limit: number
): (args: any[]) => void {
  let lastFunc: NodeJS.Timeout;
  let lastRan: number;

  return function (args: any[]) {
    if (!lastRan) {
      func(args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func(args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
}

export function timestampToTime(timestamp?: number): string {
  if (timestamp === undefined) return "";
  const date = new Date(timestamp * 1000);

  const formattedTime = date.toLocaleTimeString("fr-FR", {
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Europe/Paris",
  });

  return formattedTime;
}
