import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useFormContext } from "react-hook-form";

const UrgenceContact: React.FC = () => {
  const {
    register,
    formState: { errors },
    getValues,
    watch,
  } = useFormContext(); // utilise useFormContext pour obtenir les méthodes de formulaire

  return (
    <>
      <Box my={4}>
        <Text fontSize="1.5xl" fontFamily="montserrat" fontWeight="bold">
          Contact d'urgence
        </Text>
        <Grid
          templateColumns={{
            base: "1fr",
            md: " 1fr 1fr",
            lg: "1fr 1fr",
          }}
          mt={4}
          gap={4}
        >
          <FormControl isInvalid={!!errors.name}>
            <FormLabel htmlFor="urgence_contact.urgence_name">
              Nom du contact d'urgence
            </FormLabel>
            <Input
              {...register("urgence_contact.urgence_name")}
              placeholder=""
            />
          </FormControl>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel htmlFor="urgence_contact.urgence_firstname">
              Prénom du contact d'urgence
            </FormLabel>
            <Input
              {...register("urgence_contact.urgence_firstname")}
              placeholder=""
            />
          </FormControl>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel htmlFor="urgence_contact.urgence_link_parents">
              Lien de parenté
            </FormLabel>
            <Input
              {...register("urgence_contact.urgence_link_parents")}
              placeholder=""
            />
          </FormControl>

          <FormControl
            isInvalid={!!(errors.urgence_contact as any)?.urgence_num}
          >
            <FormLabel htmlFor="urgence_contact.urgence_num">
              Numéro d'urgence
            </FormLabel>
            <Input
              {...register("urgence_contact.urgence_num")}
              placeholder=""
            />
            {errors.urgence_contact &&
              "urgence_num" in errors.urgence_contact && (
                <FormErrorMessage>
                  {errors.urgence_contact?.urgence_num?.message as string}
                </FormErrorMessage>
              )}
          </FormControl>
        </Grid>
      </Box>
    </>
  );
};

export default UrgenceContact;
