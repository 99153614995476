// components/EditVacataire.tsx
import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  FaBriefcase,
  FaFileUpload,
  FaShirtsinbulk,
  FaUser,
} from "react-icons/fa";
import { useMutation, useQuery } from "react-query";
import { fetchMeVacataire, updateMeVacataire } from "../../api/ApiVacataire";
import InformationsForm from "../../components/Vacataire/modal/form/InformationsForm";
import InfosPerso from "../../components/Vacataire/modal/form/Perso";
import DetailsProLegal from "../../components/Vacataire/modal/form/ProLegal";
import DocumentUpload from "../../components/Vacataire/modal/form/UploadDocVacataire";
import { Vacataire } from "../../utils/types/VacataireType";
import VacataireSchema from "../../utils/validation/ValidationVacataireSchema";

const VacataireProfileView: React.FC = () => {
  const {
    data: selectedVacataire,
    isLoading,
    error,
  } = useQuery("vacataire", fetchMeVacataire, {
    refetchOnWindowFocus: false,
  });
  const toast = useToast();

  const methods = useForm({
    resolver: yupResolver(VacataireSchema as any),
    defaultValues: isLoading ? {} : selectedVacataire,
    mode: "onBlur", // vérifier les erreurs lors du blur
  });
  const [isMinor, setIsMinor] = useState(false);
  const securityChecked = methods.watch("security");
  const siapChecked = methods.watch("siap");
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };

  const handleNext = () => {
    setTabIndex((prevTabIndex) => prevTabIndex + 1);
  };

  useEffect(() => {
    methods.reset(selectedVacataire || {});
  }, [selectedVacataire, methods]);

  const mutation = useMutation(updateMeVacataire, {
    onSuccess: () => {
      toast({
        title: "Mise à jour réussie.",
        description: "Vos informations ont été mises à jour avec succès.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    },
    onError: () => {
      toast({
        title: "Erreur de mise à jour.",
        description:
          "Une erreur s'est produite lors de la mise à jour de vos informations.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    },
  });
  const onSubmit = (data: Vacataire) => {
    let updatedVacataire = { ...data };
    delete updatedVacataire.photo;
    delete updatedVacataire.autorisations;
    delete updatedVacataire.contrats;
    delete updatedVacataire.pay_slip;
    delete updatedVacataire.dpae;
    delete updatedVacataire.rib;
    delete updatedVacataire.identity_card;
    delete updatedVacataire.identity_card_verso;
    delete updatedVacataire.vital_card;
    delete updatedVacataire.proof_of_address;
    delete updatedVacataire.criminal_record;
    delete updatedVacataire.minor_work_authorisation;
    delete updatedVacataire.minor_identity_card;
    delete updatedVacataire.minor_identity_card_verso;
    delete updatedVacataire.security_card;
    delete updatedVacataire.security_degree;

    mutation.mutate(updatedVacataire);
  };
  const handleSaveAndNext = () => {
    onSubmit(methods.getValues());
    handleNext();
  };

  return (
    <Box
      maxH="96vh"
      overflowY="auto" // Ajoutez cette ligne pour activer le défilement vertical si nécessaire
    >
      <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 2 }}>
        <Box>
          <Stack>
            <Box>
              <Box py="8">
                <Flex justify="space-between" align="center" py="1">
                  <Heading
                    size={useBreakpointValue({ base: "xs", md: "sm" })}
                    color="primary"
                  >
                    Gestion de mon profil
                  </Heading>
                </Flex>
              </Box>{" "}
              <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <Tabs index={tabIndex} onChange={handleTabsChange}>
                    <TabList>
                      <Tab flex="1" gap={2}>
                        <Box as={FaUser} /> {/* Icône d'utilisateur */}
                        Identité
                      </Tab>
                      <Tab flex="1" gap={2}>
                        <Box as={FaBriefcase} />{" "}
                        {/* Icône de porte-documents */}
                        Adresse et coordonnées bancaires
                      </Tab>
                      <Tab flex="1" gap={2}>
                        <Box as={FaFileUpload} />{" "}
                        {/* Icône d'upload de fichier */}
                        Dépôt de documents
                      </Tab>
                      <Tab flex="1" gap={2}>
                        <Box as={FaShirtsinbulk} />{" "}
                        {/* Icône d'upload de fichier */}
                        Renseignements
                      </Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <Text
                          fontSize="1.5xl"
                          fontFamily="montserrat"
                          fontWeight="bold"
                        >
                          Identité
                        </Text>
                        <InfosPerso isMinor={isMinor} setIsMinor={setIsMinor} />
                      </TabPanel>
                      <TabPanel>
                        <DetailsProLegal />
                      </TabPanel>
                      <TabPanel>
                        {selectedVacataire && selectedVacataire._id ? (
                          <Grid
                            templateColumns={{
                              base: "1fr",
                              md: "1fr 1fr",
                              lg: "1fr 1fr",
                            }}
                            gap={8}
                            mt={4}
                          >
                            <DocumentUpload
                              name="photo"
                              label="Photo"
                              id={selectedVacataire._id}
                            />
                            <DocumentUpload
                              name="rib"
                              label="RIB"
                              id={selectedVacataire._id}
                            />
                            <DocumentUpload
                              name="identity_card"
                              label="Recto carte d'identité"
                              id={selectedVacataire._id}
                            />
                            <DocumentUpload
                              name="identity_card_verso"
                              label="Verso carte d'identité"
                              id={selectedVacataire._id}
                            />
                            <DocumentUpload
                              name="vital_card"
                              label="Carte vitale"
                              id={selectedVacataire._id}
                            />

                            <DocumentUpload
                              name="proof_of_address"
                              label="Justificatif de domicile"
                              id={selectedVacataire._id}
                            />
                            <DocumentUpload
                              name="criminal_record"
                              label="Casier judiciaire"
                              id={selectedVacataire._id}
                            />

                            {isMinor == true && (
                              <Grid>
                                <DocumentUpload
                                  name="minor_work_authorisation"
                                  label="Autorisation de travail pour mineur"
                                  id={selectedVacataire._id}
                                />
                                <DocumentUpload
                                  name="minor_identity_card"
                                  label="Recto de la carte d'identité du responsable"
                                  id={selectedVacataire._id}
                                />
                                <DocumentUpload
                                  name="minor_identity_card_verso"
                                  label="Verso de la carte d'identité du responsable"
                                  id={selectedVacataire._id}
                                />
                              </Grid>
                            )}
                            {(securityChecked || siapChecked) && (
                              <>
                                <DocumentUpload
                                  name="security_degree"
                                  label="Copie du diplôme officiel"
                                  id={selectedVacataire._id}
                                />
                                <DocumentUpload
                                  name="security_card"
                                  label="Copie du recyclage / MAC"
                                  id={selectedVacataire._id}
                                />
                              </>
                            )}
                          </Grid>
                        ) : (
                          <Text>
                            Veuillez créer le vacataire avant de pouvoir déposer
                            des documents.
                          </Text>
                        )}
                      </TabPanel>
                      <TabPanel>
                        <Text
                          fontSize="1.5xl"
                          fontFamily="montserrat"
                          fontWeight="bold"
                        >
                          Informations complémentaires
                        </Text>
                        <InformationsForm />
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  {tabIndex === 3 ? (
                    <Button type="submit" variant="primaryButton">
                      Enregistrer
                    </Button>
                  ) : (
                    <Button onClick={handleSaveAndNext} variant="primaryButton">
                      Sauvegarder & Avancer
                    </Button>
                  )}
                </form>
              </FormProvider>
            </Box>
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default VacataireProfileView;
