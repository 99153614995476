import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  StackProps,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthService from "../../api/auth/Service";
import { EVENTS_PAGE } from "../../utils/navigation/endpoints";
import { ToLoginType } from "../../utils/types/UserTypes";
import { loginValidationSchema } from "../../utils/validation/authentification";
import { LogoClub } from "../_shared/logo";
import { useAuth } from "./useAuth";

export const LoginForm = (props: StackProps) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [rememberMe, setRememberMe] = useState(false);
  const { login } = useAuth();

  const toast = useToast();

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ToLoginType>({
    resolver: yupResolver(loginValidationSchema),
  });

  const onSubmit = async (data: ToLoginType) => {
    setIsSubmitting(true);

    try {
      const response = await AuthService.login(data, login);
      setIsSubmitting(false);
      toast({
        title: "Connexion réussie",
        description:
          "Bienvenue sur votre espace de gestion " + response.user.firstname,

        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate(EVENTS_PAGE);
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      toast({
        title: "Erreur",
        description: "Erreur serveur, email ou mot de passe incorrect",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Stack spacing="8" {...props}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing="6">
          {isMobile && <LogoClub />}
          <Stack spacing={{ base: "2", md: "4" }} textAlign="center">
            <Heading
              size={useBreakpointValue({ base: "xs", md: "sm" })}
              color="brand.900"
              as="h1"
            >
              Connexion à votre compte
            </Heading>
            <HStack spacing="1" justify="center"></HStack>
          </Stack>
        </Stack>
        <Stack spacing="6">
          <Stack spacing="5">
            <FormControl isRequired isInvalid={!!errors.email?.message}>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                type="email"
                id="email"
                placeholder="Email"
                {...register("email")}
              />
              <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
            </FormControl>
            <FormControl
              id="password"
              isRequired
              isInvalid={!!errors?.password?.message}
            >
              <FormLabel htmlFor="password">Mot de passe</FormLabel>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="********"
                  {...register("password")}
                />
                <InputRightElement h={"full"}>
                  <Button
                    variant={"ghost"}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                  >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
            </FormControl>
          </Stack>
          <HStack justify="space-between">
            <Checkbox
              colorScheme="yellow"
              isChecked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)}
            >
              Se souvenir de moi
            </Checkbox>
            <Button
              variant="link"
              color={"secondary"}
              size="sm"
              onClick={() => navigate("/")}
            >
              Inscription
            </Button>
          </HStack>
          <Stack spacing="4">
            <Button
              isLoading={isSubmitting}
              type="submit"
              variant="primaryButton"
            >
              Se connecter
            </Button>
          </Stack>
        </Stack>
      </form>
    </Stack>
  );
};
