// DEFINE ERROR MESSAGES
export const ERROR_INVALID_CREDENTIALS = "Invalid Credentials";
export const ERROR_USER_DO_NO_EXIST = "User do not exist";
export const ERROR_EMAIL_ALREADY_TAKEN = "Email already taken";
export const ERROR_INVALID_USERNAME = "Username does not fit the requirements";
export const ERROR_CODE_INVALID = "Invalid confirmation code";
export const ERROR_RESET_EXPIRED = "Reset has expired, can not update password";
export const ERROR_RESET_WRONG_UUID =
  "Reset UUID does not match, can not update password";
export const ERROR_UNAUTHORIZED = "Unauthorized operation";
export const ERROR_NOT_FOUND_DATA = "Data not found";
export const ERROR_WRONG_RESPONSE = "Response Data not properly formatted";

export const ERROR_UNKNOWN = "Unknown error";
