import { Tab, TabList, TabPanels, Tabs } from "@chakra-ui/react";
import { ManageEvent } from "./ManageEvenements";

export const TabEventsComponents = () => (
  <Tabs size="md" variant="enclosed" color="primary">
    <TabList>
      <Tab fontWeight="bold">2023/2024</Tab>
    </TabList>
    <TabPanels>
      <ManageEvent />
    </TabPanels>
  </Tabs>
);
