import { AttachmentIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  GridItem,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../api";

interface PaySlipComponentProps {
  paySlipData: string[];
}

const PaySlipGuestComponent: React.FC<PaySlipComponentProps> = ({
  paySlipData,
}) => {
  const [localPaySlips, setLocalPaySlips] = useState<string[]>([]);

  useEffect(() => {
    setLocalPaySlips(paySlipData);
  }, [paySlipData]);

  const formatDate = (filename: string): string => {
    const match = filename.match(/_(\d{2})_(\d{4})\.pdf$/);
    return match ? `BulletinDePaie_${match[1]}_${match[2]}` : "Unknown Date";
  };

  const sortedPaySlips = localPaySlips.sort((a, b) => b.localeCompare(a));

  const handleDownload = (paySlipUrl: string) => {
    const uuid = paySlipUrl.split("/").pop()?.split("_")[0];
    if (uuid) {
      const downloadUrl = `${API_URL}/vacataires/pay-slips/download/${uuid}`;
      fetch(downloadUrl, {
        credentials: "include",
      })
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = blobUrl;
          link.download = `BulletinDePaie_${uuid}.pdf`;
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(blobUrl);
        })
        .catch((error) => console.error("Download error:", error));
    }
  };

  return (
    <Box>
      <Text
        fontSize="2.5xl"
        fontFamily="montserrat"
        fontWeight="bold"
        marginTop={"4"}
      >
        Mes bulletins de paie
      </Text>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        {sortedPaySlips.map((paySlipUrl, index) => (
          <GridItem key={index} w="100%">
            <Box p={4} borderWidth="1px" borderRadius="lg">
              <Text mb={2}>
                <AttachmentIcon mr={2} />
                {formatDate(paySlipUrl.split("/").pop()!)}
              </Text>
              <Tooltip label="Télécharger le bulletin de paie">
                <IconButton
                  as="a"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDownload(paySlipUrl)}
                  icon={<DownloadIcon />}
                  colorScheme="teal"
                  variant="outline"
                  aria-label="Télécharger"
                />
              </Tooltip>
            </Box>
          </GridItem>
        ))}
      </Grid>
    </Box>
  );
};

export default PaySlipGuestComponent;
