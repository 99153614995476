import { theme as proTheme } from "@chakra-ui/pro-theme";
import { extendTheme } from "@chakra-ui/react";

const customTheme = extendTheme(
  {
    colors: {
      primary: "#FF4553",
      secondary: "#EDCD36",
      primaryDarken: "#001a39",
      secondaryDarken: "#fb8b24",
    },
    fonts: {
      body: "Avenir, sans-serif",
      heading: "FuturaBDCN, monospace",
      mono: "Nulshock, monospace",
    },
    components: {
      Button: {
        variants: {
          primaryButton: {
            bg: "primary",
            color: "white",
            _hover: {
              bg: "primaryDarken",
              color: "white",
            },
          },
          borderedButton: {
            border: "1px solid",
            borderColor: "primary",
            bg: "secondary",
            color: "primary",
            _hover: {
              bg: "primary",
              color: "white",
            },
          },
          secondaryButton: {
            bg: "secondary",
            color: "white",
            _hover: {
              bg: "secondaryDarken",
              color: "white",
            },
          },
          secondaryLink: {
            color: "secondary",
            bg: "transparent",
            _hover: {
              color: "secondaryDarken",
              textDecoration: "underline",
            },
          },
          ghostOnAccent: {
            bg: "transparent",
            color: "on-accent-subtle",
            _hover: {
              bg: "secondary",
              color: "on-accent-subtle",
            },
            _active: {
              bg: "secondaryDarken",
              color: "on-accent-subtle",
            },
          },
        },
      },
    },
    // ...
  },
  proTheme,
);

export default customTheme;
