import { AddIcon, CheckIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  List,
  Stack,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import React, { ChangeEvent, RefObject, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { AddressFormPlace } from "../../../Vacataire/modal/form/AdressFormPlace";
import { AddressFormPlaceDisable } from "../../../Vacataire/modal/form/AdressFormPlaceDisable";

interface Place {
  name: string;
  address: {
    num: string;
    street: string;
    city: string;
    country: string;
    zip_code: string;
  };
}

interface PlaceSelectProps {
  id: string;
  label: string;
  options: Place[] | undefined;
  inputValue: string;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleAdd: (place: Place) => void;
  handleSelect: (place: Place) => void;
  handleDelete: (place: Place) => void;
  onChange: (place: Place) => void;
}

const PlaceSelect: React.FC<PlaceSelectProps> = ({
  id,
  label,
  options,
  inputValue,
  isOpen,
  setIsOpen,
  handleInputChange,
  handleAdd,
  handleSelect,
  handleDelete,
  onChange,
}) => {
  const [placeName, setPlaceName] = useState("");
  const methods = useForm();
  const toast = useToast();
  const containerRef: RefObject<HTMLDivElement> = React.useRef(null);
  const filteredOptions = options?.filter((option: Place) =>
    option.name.toLowerCase().includes(inputValue.toLowerCase())
  );

  const handleAddPlace = () => {
    const address = methods.getValues("address");

    if (placeName) {
      const newPlace: Place = {
        name: placeName,
        address: address,
      };
      handleAdd(newPlace);
    }
  };

  useEffect(() => {
    const handleDocumentClick = (event: any) => {
      if (
        isOpen &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [isOpen, setIsOpen, id]);
  {
  }

  return (
    <FormProvider {...methods}>
      <Stack spacing={3} position="relative" ref={containerRef}>
        <FormControl id={id}>
          <FormLabel htmlFor={id}>{label}</FormLabel>
          <Input
            value={inputValue}
            onChange={handleInputChange}
            onFocus={() => setIsOpen(true)}
            placeholder={`Saisir ou sélectionner ${label} ...`}
          />
        </FormControl>
        {isOpen && (
          <List
            position="absolute"
            top="100%"
            zIndex="dropdown"
            bg="white"
            boxShadow="sm"
            p="2"
            mt="2"
            borderRadius="md"
            width="100%"
          >
            <Accordion allowToggle>
              {filteredOptions?.map((option: Place) => (
                <AccordionItem key={option.name}>
                  <AccordionButton>
                    <Tooltip
                      label="Sélectionner ce lieu"
                      aria-label="Sélectionner ce lieu"
                    >
                      <IconButton
                        aria-label="Sélectionner ce lieu"
                        icon={<CheckIcon />}
                        size="sm"
                        mr={2}
                        onClick={() => {
                          handleSelect(option);
                          onChange(option); // Assurez-vous d'appeler onChange ici
                        }}
                      />
                    </Tooltip>
                    <Box flex="1" textAlign="left">
                      {option.name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <AccordionPanel pb={4}>
                    <Box mb={4}>Nom du lieu: {option.name}</Box>

                    <AddressFormPlaceDisable
                      key={option.name}
                      uniqueId={option.name} // Ajoutez cette ligne
                      formPrefix="place"
                      disable={true}
                      defaultValue={option.address}
                    />
                    <Button
                      mt={4}
                      colorScheme="red"
                      onClick={() => {
                        handleDelete(option); // Supprimez l'option actuelle à partir d'ici
                      }}
                    >
                      Supprimer
                    </Button>
                  </AccordionPanel>
                </AccordionItem>
              ))}

              <AccordionItem>
                <h2>
                  <AccordionButton
                    onClick={(event) => {
                      event.stopPropagation();
                    }}
                  >
                    <Box flex="1" textAlign="left">
                      <AddIcon mr={2} /> Ajouter un nouveau lieu
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4}>
                  <Input
                    placeholder="Nom du lieu"
                    value={placeName}
                    onChange={(e) => setPlaceName(e.target.value)}
                  />
                  <AddressFormPlace />
                  <Button
                    mt={4}
                    onClick={() => {
                      handleAddPlace();
                    }}
                    isDisabled={!placeName.trim()} // Désactive le bouton si placeName est vide ou ne contient que des espaces
                  >
                    Ajouter
                  </Button>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </List>
        )}
      </Stack>
    </FormProvider>
  );
};

export default PlaceSelect;
