import { AttachmentIcon, DeleteIcon, DownloadIcon } from "@chakra-ui/icons";
import {
  Box,
  Divider,
  Flex,
  Grid,
  IconButton,
  Text,
  Tooltip,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { API_URL } from "../../api";
import FileDropzone from "../_shared/interaction/FileDropzone";

interface CombinedComponentProps {
  vacataireId: string;
  contratsData?: string[];
  dpaesData?: string[];
}
interface BlobInfo {
  blobURL: string;
  filename?: string | null; // Ajout de null comme type autorisé pour filename
}

type CombinedDataItem = {
  contratName?: string; // Utilisez ? si la propriété peut être optionnelle
  dpaeName?: string;
  eventName: string;
  date: string;
};

function extractUUIDFromFileName(fileName: string): string | null {
  const uuidRegex =
    /[a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12}/;
  const match = fileName.match(uuidRegex);
  return match ? match[0] : null;
}

export const CombinedComponent: React.FC<CombinedComponentProps> = ({
  vacataireId,
  contratsData,
  dpaesData,
}) => {
  const flexDirection = useBreakpointValue({ base: "column", lg: "row" }) as
    | "column"
    | "row"
    | undefined;

  const [contrats, setContrats] = useState<string[]>(contratsData || []);
  const [dpaes, setDpaes] = useState<string[]>(dpaesData || []);
  const toast = useToast();
  const isValidFileName = (filename: string) => {
    // Regex pour vérifier la présence d'une date sous la forme yyyy-mm-dd
    const yyyyMMddRegex = /\d{4}-\d{2}-\d{2}/;
    // Regex pour vérifier la présence d'une date sous la forme dd-mm-yyyy
    const ddMMyyyyRegex = /\d{2}-\d{2}-\d{4}/;

    let match = filename.match(yyyyMMddRegex);
    let format = "yyyy-mm-dd";

    if (!match) {
      match = filename.match(ddMMyyyyRegex);
      format = "dd-mm-yyyy";
    }

    if (!match) {
      return {
        valid: false,
        message:
          "Le nom du fichier doit contenir une date (yyyy-mm-dd ou dd-mm-yyyy).",
      };
    }

    // Vérification pour s'assurer que la date est valide
    const dateParts = match[0].split("-");
    if (format === "yyyy-mm-dd") {
      if (!isValidDate(dateParts[0], dateParts[1], dateParts[2])) {
        return {
          valid: false,
          message: "La date dans le nom du fichier n'est pas valide.",
        };
      }
      const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`; // Conversion de yyyy-mm-dd en dd-mm-yyyy
      filename = filename.replace(yyyyMMddRegex, formattedDate);
    } else {
      if (!isValidDate(dateParts[2], dateParts[1], dateParts[0])) {
        return {
          valid: false,
          message: "La date dans le nom du fichier n'est pas valide.",
        };
      }
    }

    return { valid: true, message: "", newFilename: filename };
  };

  // Fonction pour vérifier si une date est valide
  const isValidDate = (year: string, month: string, day: string) => {
    const y = parseInt(year, 10);
    const m = parseInt(month, 10);
    const d = parseInt(day, 10);
    const date = new Date(y, m - 1, d);
    return (
      date &&
      date.getMonth() + 1 === m &&
      date.getDate() === d &&
      date.getFullYear() === y
    );
  };

  const [blobURLs, setBlobURLs] = useState<Record<string, BlobInfo>>({});
  const handleDelete = async (documentPath: string, type: string) => {
    const uuid = extractUUIDFromFileName(documentPath);

    // Choisir l'URL en fonction du type
    let endpoint;
    if (type === "contrat") {
      endpoint = `${API_URL}/vacataires/delete-contract/${uuid}`;
    } else if (type === "dpae") {
      endpoint = `${API_URL}/vacataires/delete-dpae/${uuid}`;
    } else {
      console.error("Unknown file type:", type);
      return;
    }

    try {
      const response = await fetch(endpoint, {
        method: "DELETE",
        credentials: "include",
      });
      if (!response.ok) {
        throw new Error(`Failed to delete the ${type}`);
      }

      // Mettez à jour l'état local après la suppression réussie
      let newContrats = contrats;
      let newDpaes = dpaes;
      if (type === "contrat") {
        newContrats = newContrats.filter((contrat) => contrat !== documentPath);
        setContrats(newContrats);
      } else if (type === "dpae") {
        newDpaes = newDpaes.filter((dpae) => dpae !== documentPath);
        setDpaes(newDpaes);
      }

      const combinedData = associateDataByDate(newContrats, newDpaes);
      setCombinedDataItems(combinedData);

      // Affichez le toast
      toast({
        title: `${type.charAt(0).toUpperCase() + type.slice(1)} supprimé.`,
        description: `Le ${type} a été supprimé avec succès.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: `Erreur de suppression.`,
        description: `Échec de la suppression du ${type}. Veuillez réessayer ou contacter l'administrateur.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [combinedDataItems, setCombinedDataItems] = useState<
    CombinedDataItem[]
  >([]);
  const extractEventNameAndDate = (
    filename: string
  ): { eventName: string; date: string } | null => {
    const datePattern = /\d{2}-\d{2}-\d{4}/;
    const match = filename.match(datePattern);
    if (match) {
      const date = match[0];

      // Utilisez une expression régulière pour extraire le nom de l'événement sans "CDD-"
      const eventNamePattern = /\/contrats\/CDD-(.*?)-\d{2}-\d{2}-\d{4}/;
      const eventNameMatch = filename.match(eventNamePattern);
      const eventName = eventNameMatch
        ? eventNameMatch[1]
        : "Événement inconnu";

      return { eventName, date };
    }
    return null;
  };

  const onDrop = async (
    acceptedFiles: File[],
    fileType: "contrat" | "dpae",
    contractDate?: string
  ) => {
    const formData = new FormData();
    console.log("========>");
    console.log(contractDate);
    if (acceptedFiles.length > 1) {
      toast({
        title: "Erreur de dépôt.",
        description: "Veuillez déposer un seul fichier à la fois.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    let formattedDate = "";
    if (fileType === "dpae" && contractDate) {
      formattedDate = formatContractDate(contractDate);
      console.log("formatte");
      console.log(formattedDate);
    }

    for (const file of acceptedFiles) {
      let finalFileName =
        fileType === "dpae" && formattedDate
          ? `DPAE ${formattedDate}.pdf`
          : file.name;

      console.log("====>");
      console.log(finalFileName);

      const newFile = new File([file], finalFileName, { type: file.type });
      formData.append("files", newFile);
    }

    const uploadUrl =
      fileType === "contrat"
        ? `${API_URL}/vacataires/upload-contrats/contrats/${vacataireId}`
        : `${API_URL}/vacataires/upload-dpae/dpae/${vacataireId}`;

    try {
      const response = await fetch(uploadUrl, {
        method: "POST",
        credentials: "include",
        body: formData,
      });

      if (!response.ok) {
        throw new Error("Failed to upload the files");
      }

      const data = await response.json();

      if (Array.isArray(data.uploadedFilePaths)) {
        let newContrats = contrats;
        let newDpaes = dpaes;

        if (fileType === "contrat") {
          newContrats = [...contrats, ...data.uploadedFilePaths];
          setContrats(newContrats);
        } else if (fileType === "dpae") {
          newDpaes = [
            ...dpaes,
            ...data.uploadedFilePaths.map(() => `DPAE ${formattedDate}.pdf`),
          ];
          setDpaes(newDpaes);
        }

        const combinedData = associateDataByDate(newContrats, newDpaes);
        setCombinedDataItems(combinedData);
      } else {
        console.warn(
          "uploadedFilePaths is missing or not an array in the API response"
        );
      }

      const newBlobURLs: Record<string, BlobInfo> = {};

      for (const filepath of data.uploadedFilePaths) {
        const uuid = extractUUIDFromFileName(filepath);
        const downloadUrl =
          fileType === "contrat"
            ? `${API_URL}/vacataires/download-contract/${vacataireId}/${uuid}`
            : `${API_URL}/vacataires/download-dpae/${vacataireId}/${uuid}`;

        const blob = await fetchBlobFromURL(downloadUrl);
        const blobURL = URL.createObjectURL(blob);
        newBlobURLs[downloadUrl] = { blobURL: blobURL, filename: filepath };
      }

      setBlobURLs((prevURLs) => ({ ...prevURLs, ...newBlobURLs }));

      toast({
        title: `${fileType === "contrat" ? "Contrat" : "Dpae"} téléchargé.`,
        description: `Le ${
          fileType === "contrat" ? "contrat" : "dpae"
        } a été téléchargé avec succès.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error:", error);
      toast({
        title: "Échec du téléchargement.",
        description: `Une erreur s'est produite lors du téléchargement du ${
          fileType === "contrat" ? "contrat" : "dpae"
        }.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const formatContractDate = (dateString: string) => {
    // Convertir la date en format MM/DD/YYYY
    const parts = dateString.split("-");
    const formattedDateString = `${parts[1]}/${parts[0]}/${parts[2]}`;

    // Créer un nouvel objet Date
    const date = new Date(formattedDateString);

    // Extraire le mois, le jour et l'année
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();

    // Retourner la date formatée
    return `${day}-${month}-${year}`;
  };

  const extractDateFromFilename = (filename: string): string | null => {
    const datePattern = /\d{2}-\d{2}-\d{4}/;
    const match = filename.match(datePattern);
    return match ? match[0] : null;
  };

  async function fetchBlobFromURL(url: string) {
    const response = await fetch(url, {
      credentials: "include", // Ajout de cette ligne
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return response.blob();
  }
  const associateDataByDate = (
    contratsList: string[],
    dpaesList: string[]
  ): CombinedDataItem[] => {
    let combinedData: CombinedDataItem[] = [];

    // Pour chaque contrat, trouvez le DPAE correspondant et ajoutez-le à combinedData
    contratsList.forEach((contrat) => {
      const eventData = extractEventNameAndDate(contrat);
      if (eventData) {
        const matchingDPAE = dpaesList.find((dpae) => {
          const dateInDPAE = extractDateFromFilename(dpae);
          return dateInDPAE === eventData.date;
        });

        // Avant de pousser les données, vérifiez si la date existe déjà dans combinedData
        const existingData = combinedData.find(
          (item) => item.date === eventData.date
        );
        if (!existingData) {
          combinedData.push({
            eventName: eventData.eventName,
            contratName: contrat,
            dpaeName: matchingDPAE || "",
            date: eventData.date,
          });
        }
      }
    });

    dpaesList.forEach((dpae) => {
      const dateInDPAE = extractDateFromFilename(dpae);
      if (
        dateInDPAE &&
        !combinedData.find((item) => item.date === dateInDPAE)
      ) {
        combinedData.push({
          eventName: "DPAE", // ou une autre valeur par défaut
          contratName: "",
          dpaeName: dpae,
          date: dateInDPAE,
        });
      }
    });

    // Trier par date décroissante
    combinedData.sort((a, b) => {
      const dateA = new Date(a.date.split("-").reverse().join("-"));
      const dateB = new Date(b.date.split("-").reverse().join("-"));
      return dateB.getTime() - dateA.getTime();
    });

    return combinedData;
  };
  const downloadBlob = (blob: Blob, filename: string) => {
    const blobURL = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = blobURL;
    anchor.download = filename;
    anchor.click();
    URL.revokeObjectURL(blobURL); // Nettoie l'URL Object pour libérer des ressources
  };

  const fetchBlobAndFilenameFromURL = async (url: string) => {
    const response = await fetch(url, {
      credentials: "include", // Ajout de cette ligne
    });
    const blob = await response.blob();

    // Extraire le nom du fichier de l'en-tête Content-Disposition
    const contentDisp = response.headers.get("Content-Disposition");
    let filename = "default_filename.pdf"; // Valeur par défaut

    if (contentDisp) {
      const matches = contentDisp.match(/filename="(.+)"/);
      if (matches && matches.length > 1) {
        filename = matches[1];
      }
    }

    return { blob, filename };
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      let combinedData = associateDataByDate(contrats, dpaes);
      setCombinedDataItems(combinedData);
      for (let i = 0; i < combinedData.length; i++) {
        let { contratName, dpaeName } = combinedData[i];

        // Récupération du contrat
        if (contratName) {
          const uuid = extractUUIDFromFileName(contratName);
          console.log("contract extrat =+=>");

          let contratUrl = `${API_URL}/vacataires/download-contract/${vacataireId}/${uuid}`;
          console.log("URL Contrat:", contratUrl);

          try {
            const { blob, filename } = await fetchBlobAndFilenameFromURL(
              contratUrl
            );
            const blobURL = URL.createObjectURL(blob);
            setBlobURLs((prevURLs) => ({
              ...prevURLs,
              [contratUrl]: { blobURL, filename },
            }));
          } catch (error) {
            console.error("Error fetching contrat blob:", error);
          }
        }

        // Récupération du DPAE
        if (dpaeName) {
          const uuid = extractUUIDFromFileName(dpaeName);

          console.log("uuid extrat =+=>");
          console.log(dpaeName);
          console.log(uuid);

          let dpaeUrl = `${API_URL}/vacataires/download-dpae/${vacataireId}/${uuid}`;
          console.log("URL DPAE:", dpaeUrl);

          try {
            const { blob, filename } = await fetchBlobAndFilenameFromURL(
              dpaeUrl
            );
            const blobURL = URL.createObjectURL(blob);
            setBlobURLs((prevURLs) => ({
              ...prevURLs,
              [dpaeUrl]: { blobURL, filename },
            }));
          } catch (error) {
            console.error("Error fetching DPAE blob:", error);
          }
        }
      }
    };

    fetchDocuments();
  }, [vacataireId, contratsData, dpaesData]);

  function downloadFromBlobURL(blobURL: string, filename: string) {
    const link = document.createElement("a");
    link.href = blobURL;
    link.download = filename;
    console.log("======> filename");
    console.log(filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  const determineBorderColor = (contratName?: string, dpaeName?: string) => {
    if (!contratName || !dpaeName) {
      return "orange";
    } else {
      return "green";
    }
  };

  return (
    <Box
      mt={4}
      p={4}
      boxShadow="md"
      borderWidth="1px"
      borderRadius="md"
      maxHeight="500px"
      overflowY="auto"
    >
      <FileDropzone
        label="Glisser ou déposer un nouveau contrat"
        onFileDrop={(acceptedFiles) => onDrop(acceptedFiles, "contrat")}
        id={`new-contrat-${vacataireId}`} // Un id unique pour le nouveau contrat
      />
      <Divider my={4} />{" "}
      {/* Une ligne de séparation entre le FileDropzone et la liste des contrats */}
      <Grid
        templateColumns={{ base: "1fr", md: "1fr 1fr", lg: "1fr 1fr" }}
        gap={4}
      >
        {combinedDataItems.map((item, index) => {
          const uuidContract = item.contratName
            ? extractUUIDFromFileName(item.contratName)
            : null;

          const uuidDpae = item.dpaeName
            ? extractUUIDFromFileName(item.dpaeName)
            : null;

          return (
            <Box
              key={index}
              boxShadow="md"
              borderWidth="2px"
              borderRadius="md"
              borderColor={determineBorderColor(
                item.contratName,
                item.dpaeName
              )}
              p={4}
            >
              {/* Date affichée au-dessus de la boîte */}
              <Text fontSize="sm" mb={0}>
                {item.date}
              </Text>

              {/* Ici commence la section des contrats et DPAE */}
              <Flex flexDirection="row" alignItems="center">
                {item.contratName ? (
                  <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex flexDirection="row" alignItems="center" mb={4}>
                      <AttachmentIcon mr={2} />
                      <Text
                        isTruncated
                        maxWidth="180px"
                        whiteSpace="nowrap"
                        fontSize="sm"
                      >
                        {item.eventName}
                      </Text>
                    </Flex>

                    <Flex justifyContent="center">
                      <Tooltip label="Télécharger le contrat">
                        <IconButton
                          as="a"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const blobData =
                              blobURLs[
                                `${API_URL}/vacataires/download-contract/${vacataireId}/${uuidContract}`
                              ];
                            if (blobData) {
                              downloadFromBlobURL(
                                blobData.blobURL,
                                blobData.filename || "default-name.pdf"
                              );
                            }
                          }}
                          target="_blank"
                          icon={<DownloadIcon />} // Supposons que EyeIcon soit une icône pour visualiser
                          colorScheme="teal"
                          variant="outline"
                          aria-label="Visualiser"
                          mr={2} // Juste pour un peu d'espacement entre les boutons
                        />
                      </Tooltip>
                      <Tooltip label="Visualiser le contrat">
                        <IconButton
                          as="a"
                          href={
                            blobURLs[
                              `${API_URL}/vacataires/download-contract/${vacataireId}/${uuidContract}`
                            ]?.blobURL || "#"
                          }
                          target="_blank"
                          icon={<FaEye />} // Supposons que EyeIcon soit une icône pour visualiser
                          colorScheme="teal"
                          variant="outline"
                          aria-label="Visualiser"
                          mr={2} // Juste pour un peu d'espacement entre les boutons
                        />
                      </Tooltip>
                      <Tooltip label="Supprimer le contrat">
                        <IconButton
                          icon={<DeleteIcon />}
                          color="red"
                          variant="outline"
                          onClick={() => {
                            if (item.contratName) {
                              handleDelete(item.contratName, "contrat");
                            }
                          }}
                          aria-label="Supprimer"
                        />
                      </Tooltip>
                    </Flex>
                  </Box>
                ) : (
                  <Box flex="1">
                    <FileDropzone
                      label="Déposer un contrat"
                      onFileDrop={(acceptedFiles) =>
                        onDrop(acceptedFiles, "contrat")
                      }
                      id={vacataireId}
                    />
                  </Box>
                )}
                <Divider
                  orientation="vertical"
                  borderColor="black"
                  mx={4}
                  width="2px"
                  height="90px"
                />

                {/* DPAE*/}
                {item.dpaeName ? (
                  <Box
                    flex={1}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex flexDirection="row" alignItems="center" mb={4}>
                      <AttachmentIcon mr={2} />
                      <Text fontSize="sm" isTruncated>
                        DPAE - {item.date}
                      </Text>
                    </Flex>

                    <Flex justifyContent="center">
                      <Tooltip label="Télécharger la DPAE">
                        <IconButton
                          as="a"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const blobData =
                              blobURLs[
                                `${API_URL}/vacataires/download-dpae/${vacataireId}/${uuidDpae}`
                              ];
                            if (blobData) {
                              downloadFromBlobURL(
                                blobData.blobURL,
                                blobData.filename || "default-name.pdf"
                              );
                            }
                          }}
                          target="_blank"
                          icon={<DownloadIcon />}
                          colorScheme="teal"
                          variant="outline"
                          aria-label="Télécharger"
                          mr={2}
                        />
                      </Tooltip>
                      <Tooltip label="Visualiser la DPAE">
                        <IconButton
                          as="a"
                          href={
                            blobURLs[
                              `${API_URL}/vacataires/download-dpae/${vacataireId}/${uuidDpae}`
                            ]?.blobURL || "#"
                          }
                          target="_blank"
                          icon={<FaEye />}
                          colorScheme="teal"
                          variant="outline"
                          aria-label="Visualiser"
                          mr={2}
                        />
                      </Tooltip>

                      <Tooltip label="Supprimer la dpae">
                        <IconButton
                          icon={<DeleteIcon />}
                          color="red"
                          variant="outline"
                          aria-label="Supprimer"
                          onClick={() => {
                            if (item.dpaeName) {
                              handleDelete(item.dpaeName, "dpae");
                            }
                          }}
                        />
                      </Tooltip>
                    </Flex>
                  </Box>
                ) : (
                  <Box flex="1">
                    <FileDropzone
                      label="Déposer une dpae"
                      onFileDrop={(acceptedFiles) => {
                        if (item.contratName) {
                          onDrop(acceptedFiles, "dpae", item.date);
                        }
                      }}
                      id={vacataireId}
                    />
                  </Box>
                )}
              </Flex>
            </Box>
          );
        })}{" "}
      </Grid>
    </Box>
  );
};

export default CombinedComponent;
