import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  List,
  ListIcon,
  ListItem,
  useToast,
} from "@chakra-ui/react";
import { ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";
import { GetInput } from "../../../../utils/types/EventType";

interface CustomSelectProps {
  id: string;
  label: string;
  options: GetInput[] | undefined;
  inputValue: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>; // <- Ajoutez ceci
  handleInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleAdd: () => void;
  handleSelect: (option: GetInput) => void;
  handleDelete: (option: GetInput) => void;

  onChange: (option: GetInput) => void; // <- Ajoutez ceci
}
const CustomSelect: React.FC<CustomSelectProps> = ({
  id,
  label,
  options,
  inputValue,
  isOpen,
  setIsOpen, // <- Extrayez setIsOpen de vos props
  handleInputChange,
  handleAdd,
  handleSelect,
  handleDelete,

  onChange,
}) => {
  const toast = useToast();

  useEffect(() => {
    console.log("===> custom", isOpen);

    const handleDocumentClick = (event: any) => {
      if (isOpen && !event.target.closest(`#${id}`)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleDocumentClick);
    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [isOpen, setIsOpen, id]);

  return (
    <Box id={id} position="relative">
      <FormControl id={id}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <Input
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsOpen(true)}
          placeholder={`Saisir ou sélectionner ${label} ...`}
        />
      </FormControl>
      {isOpen && (
        <List
          position="absolute"
          zIndex="dropdown"
          bg="white"
          boxShadow="sm"
          p="2"
          mt="2"
          borderRadius="md"
          width="100%"
        >
          {options?.map((option: GetInput) => (
            <ListItem
              key={option.name}
              py="1"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              transition="box-shadow 0.2s"
              _hover={{ boxShadow: "lg" }}
            >
              <Box
                sx={{ cursor: "pointer" }}
                onClick={(event) => {
                  event.stopPropagation();
                  handleSelect(option);
                  onChange(option);
                }}
              >
                {option.name}
              </Box>
              <IconButton
                aria-label="Supprimer"
                variant="outline"
                size="sm"
                icon={<DeleteIcon color="red" />}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(option);
                }}
              />
            </ListItem>
          ))}
          {inputValue &&
            !options?.some((option) => option.name === inputValue) && (
              <ListItem
                mt="2"
                py="1"
                bg="gray.100"
                onClick={(event) => {
                  event.stopPropagation();
                  handleAdd();
                }}
                sx={{ cursor: "pointer" }}
                _hover={{ boxShadow: "lg" }}
              >
                <ListIcon as={AddIcon} boxSize={4} ml="2" />
                Ajouter "{inputValue}"
              </ListItem>
            )}
        </List>
      )}
    </Box>
  );
};

export default CustomSelect;
