import React from "react";
import { TabEventsComponents } from "../../components/Evenements/TabComponents";
import ContentContainer from "../../components/_shared/ContentContainer";

const EvenementsView: React.FC = () => {
  return (
    <ContentContainer fullWidth>
      <TabEventsComponents />
    </ContentContainer>
  );
};

export default EvenementsView;
