import {
  Box,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getNotification } from "../../api/ApiNotification";
import { useAuth } from "../../components/Authentification/useAuth";
import { NotificationTable } from "../../components/_shared/notification/TableNotification";
import { EmptyState } from "../../components/_shared/state/EmptyState";
import { NotificationType } from "../../utils/types/NotificationType";

export const NotificationView = () => {
  const imagePath = "/images/empty_state.png";
  const navigate = useNavigate();
  const { logout } = useAuth();

  const fetchNotifications = async () => {
    const response = await getNotification(); // suppose que cela retourne une réponse fetch

    if (!response.ok && response.status === 401) {
      // Déconnecter l'utilisateur si la réponse est 401 Unauthorized
      logout();
      navigate("/login");
      throw new Error("Unauthorized");
    }
    return response;
  };

  const {
    data: notifications,
    isError,
    error,
    refetch,
  } = useQuery<NotificationType[], Error>("notifications", fetchNotifications, {
    onError: (error: any) => {
      // Handle other errors
      console.error("Error fetching notifications:", error);
    },
  });

  const isDataEmpty =
    notifications?.length === 0 || notifications?.length === undefined;

  return (
    <Container py={{ base: "4", md: "8" }} px={{ base: "0", md: 2 }}>
      <Box>
        <Stack>
          <Box>
            <Box py="12">
              <Flex justify="space-between" align="center" py="1">
                <Heading
                  size={useBreakpointValue({ base: "xs", md: "sm" })}
                  color="primary"
                >
                  Gestion des notifications
                </Heading>
              </Flex>
              <Text color="muted">
                Sur cette page vous pouvez consulter vos notifications
              </Text>
            </Box>

            <Box
              boxShadow={{ base: "xlg", md: "xlg" }}
              borderRadius={{ base: "none", md: "lg" }}
            >
              <Stack spacing="5">
                {isDataEmpty ? (
                  <EmptyState
                    imagePath={imagePath}
                    title="Aucune notification trouvée"
                    description="Il semble que vous n'ayez pas encore de notifications."
                    textButton=""
                  />
                ) : (
                  <NotificationTable data={notifications ?? []} />
                )}
              </Stack>
            </Box>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
};
