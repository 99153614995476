import { Box, FormControl, FormLabel, Grid, Input } from "@chakra-ui/react";
import PlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { Controller, useFormContext } from "react-hook-form";

interface AddressFormProps {
  formPrefix?: string;
  disable?: boolean;
}
interface AddressComponents {
  long_name: string;
  short_name: string;
  types: string[];
}

interface PlaceResult {
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
    viewport: {
      northeast: {
        lat: number;
        lng: number;
      };
      southwest: {
        lat: number;
        lng: number;
      };
    };
  };
  name: string;
  place_id: string;
  types: string[];
  address_components: AddressComponents[];
}

export const AddressForm: React.FC<AddressFormProps> = ({
  formPrefix,
  disable,
}) => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext(); // utilise useFormContext pour obtenir les méthodes de formulaire
  const prefix = formPrefix ? `${formPrefix}.` : "";

  const handleSelect = (newValue: any) => {
    if (newValue !== null) {
      geocodeByPlaceId(newValue.value.place_id)
        .then(async (geocodeResults) => {
          const addressComponents = geocodeResults[0].address_components;
          const streetNumber = addressComponents?.find(
            (component: { types: string[] }) =>
              component.types.includes("street_number")
          )?.long_name;
          const streetName = addressComponents?.find(
            (component: { types: string[] }) =>
              component.types.includes("route")
          )?.long_name;
          const city = addressComponents?.find(
            (component: { types: string[] }) =>
              component.types.includes("locality")
          )?.long_name;
          const country = addressComponents?.find(
            (component: { types: string[] }) =>
              component.types.includes("country")
          )?.long_name;
          const zip = addressComponents?.find(
            (component: { types: string[] }) =>
              component.types.includes("postal_code")
          )?.long_name;

          // Utiliser setValue pour mettre à jour les valeurs du champ
          // Utiliser setValue pour mettre à jour les valeurs du champ
          const prefix = formPrefix ? `${formPrefix}.` : "";
          setValue(`${prefix}address.num`, streetNumber);
          setValue(`${prefix}address.street`, streetName);
          setValue(`${prefix}address.city`, city);
          setValue(`${prefix}address.country`, country);
          setValue(`${prefix}address.zip_code`, zip);
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <Box>
      <Grid
        templateColumns={{
          base: "1fr",
          md: "1fr ",
          lg: "1fr",
        }}
        mt={formPrefix == "place" ? "4" : "4"}
        gap={4}
      >
        <PlacesAutocomplete
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
          debounce={500}
          selectProps={{
            placeholder: "Rechercher ...",
            noOptionsMessage: () => "Pas de résultats",
            loadingMessage: () => "Chargement...",
            isDisabled: disable,
            onChange: handleSelect,
          }}
        />
      </Grid>

      <Grid
        templateColumns={{
          base: "1fr",
          md: "1fr 6fr",
          lg: "1fr 6fr",
        }}
        gap={4}
        mt={4}
      >
        <FormControl id="num">
          <FormLabel>Numéro</FormLabel>
          <Controller
            name={`${prefix}address.num`}
            render={({ field }) => (
              <Input
                disabled={disable}
                type="number"
                {...field}
                onChange={(e) => {
                  const value = parseFloat(e.target.value);
                  field.onChange(isNaN(value) ? undefined : value);
                }}
              />
            )}
          />
        </FormControl>
        <FormControl id="street">
          <FormLabel>Rue</FormLabel>
          <Controller
            name={`${prefix}address.street`}
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>
      </Grid>

      <Grid
        templateColumns={{
          base: "1fr",
          md: "1fr 4fr 2fr",
          lg: "1fr 4fr 2fr",
        }}
        gap={4}
        mt={4}
      >
        <FormControl id="zip_code">
          <FormLabel>C.P.</FormLabel>
          <Controller
            name={`${prefix}address.zip_code`}
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>
        <FormControl id="city">
          <FormLabel>Ville</FormLabel>
          <Controller
            name={`${prefix}address.city`}
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>
        <FormControl id="country">
          <FormLabel>Pays</FormLabel>
          <Controller
            name={`${prefix}address.country`}
            render={({ field }) => <Input {...field} />}
          />
        </FormControl>
      </Grid>
    </Box>
  );
};
