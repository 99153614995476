import { Box, Flex, Progress, Text } from "@chakra-ui/react";

export const CustomStepper = ({ currentStep }: { currentStep: number }) => {
  const steps = [
    "Dépôts des fichiers",
    "Envoi des fichiers",
    "Résultat du traitement",
  ];

  return (
    <Box p={4} width="full">
      <Flex justifyContent="space-between" mb={2}>
        {steps.map((step, index) => (
          <Text
            key={index}
            fontWeight={currentStep === index + 1 ? "bold" : "normal"}
            color={currentStep >= index + 1 ? "yellow.500" : "gray.500"}
          >
            {step}
          </Text>
        ))}
      </Flex>
      <Progress
        colorScheme={currentStep === 3 ? "green" : "yellow"}
        size="xs"
        value={(100 / steps.length) * currentStep} // Chaque étape vaut un tiers de la barre de progression
        borderRadius="md"
      />
    </Box>
  );
};
