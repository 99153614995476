import { API_PLACE, API_URL } from "../ApiPaths";

export type PlaceType = {
  _id?: string;
  name: string;
  address: {
    num: string;
    street: string;
    city: string;
    country: string;
    zip_code: string;
  };
};

export const getPlaces = async (): Promise<PlaceType[]> => {
  const res = await fetch(API_URL + API_PLACE, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};

export const addNewPlace = async (newPlace: PlaceType) => {
  const res = await fetch(API_URL + API_PLACE, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newPlace),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return res.json();
};

export const deletePlace = async (id: string) => {
  const res = await fetch(`${API_URL}${API_PLACE}/${id}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return res.json();
};
