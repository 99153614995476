// DeleteEventModal.tsx

import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  vacataireDetails: {
    zoneName: string;
    vacataireId: string;
    eventId: string;
    serviceName: string;
  };
  onDeleteSuccess: () => void;
  deleteVacataireMutation: any; // Vous pouvez ajouter un type plus spécifique si nécessaire
};

const DeleteVacataireFromZone: React.FC<Props> = ({
  isOpen,
  onClose,
  vacataireDetails,
  onDeleteSuccess,
  deleteVacataireMutation,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Supprimer le vacataire de {vacataireDetails.zoneName}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>Êtes-vous sûr de vouloir supprimer ce vacataire ?</ModalBody>
        <ModalFooter>
          <Button mr={3} variant="ghost" onClick={onClose}>
            Annuler
          </Button>
          <Button
            colorScheme="red"
            onClick={() => deleteVacataireMutation.mutate(vacataireDetails)}
          >
            Confirmer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteVacataireFromZone;
