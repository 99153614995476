import { As, Button, ButtonProps, HStack, Icon, Text } from "@chakra-ui/react";

interface NavButtonProps extends ButtonProps {
  icon: As;
  label: string;
}

export const NavButton = (props: NavButtonProps) => {
  const { icon, label, "aria-current": ariaCurrent, ...buttonProps } = props;
  return (
    <Button
      variant="ghostOnAccent"
      justifyContent="start"
      bg={ariaCurrent === "page" ? "secondary" : undefined} // Changer le background selon l'etat actif
      color={ariaCurrent === "page" ? "white" : undefined} // Changer la couleur du texte si nécessaire
      {...buttonProps}
    >
      <HStack spacing="3">
        <Icon as={icon} boxSize="6" />
        <Text>{label}</Text>
      </HStack>
    </Button>
  );
};
