import { Text, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addNewDressCode,
  deleteDressCode,
  getDressCode,
} from "../../../api/zones/DressCode";
import { DressCode } from "../../../utils/types/EventType";
import { LoaderSpinner } from "../../_shared/loader/CustomSpinner";
import CustomSelectModal from "../modal/form/CustomSelectModal";
import NewDressCodeModal from "../modal/NewDressCodeModal";

interface DressCodeSelectorProps {
  dressCodeInput: string;
  onInputChange: (value: string) => void;
  isDisabled: boolean;
  onSelect: (name: string) => void; // Ajoutez cette ligne
}

export const DressCodeSelector: React.FC<DressCodeSelectorProps> = ({
  dressCodeInput, // Utilisez dressCodeInput comme prop
  onInputChange,
  onSelect,
  isDisabled,
}) => {
  const [isDressCodeOpen, setIsDressCodeOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const toast = useToast();
  const {
    data: dressCode,
    isLoading: dressCodesLoading,
    error: dressCodesError,
  } = useQuery<DressCode[], Error>("dressCode", getDressCode);
  const queryClient = useQueryClient();

  const handleOpenModal = (dressCodeStr: string) => {
    onInputChange(dressCodeStr);
    console.log(dressCodeStr);

    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const handleDressCodeInputChange = (e: any) => onInputChange(e.target.value);

  const addDressCodeMutation = useMutation(
    (newDressCode: { name: string; description: string }) =>
      addNewDressCode(newDressCode.name, newDressCode.description),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("dressCode");
      },
    }
  );

  const deleteDressCodeMutation = useMutation(deleteDressCode, {
    onSettled: () => {
      queryClient.invalidateQueries("dressCode");
    },
  });

  const handleAddDressCode = (name: string, description: string) => {
    if (
      name &&
      !dressCode?.some((dressCode: DressCode) => dressCode.name === name)
    ) {
      addDressCodeMutation.mutate({ name: name, description: description });
      toast({
        title: `DressCode ajouté`,
        description: `Le dressCode ${name} a été ajouté`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      onInputChange("");
    }
  };

  const handleSelectDressCode = (dressCode: DressCode) => {
    onInputChange(dressCode.name);
    onSelect(dressCode.name);
    setIsDressCodeOpen(false);
  };

  const handleDeleteDressCode = (dressCode: DressCode) => {
    deleteDressCodeMutation.mutate(dressCode._id);
    toast({
      title: `DressCode supprimé`,
      description: `Le dressCode ${dressCode.name} a été supprimé`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    onInputChange("");
  };

  // Grouping DressCode related states and handlers
  const dressCodeState = {
    inputValue: dressCodeInput,
    isOpen: isDressCodeOpen,
    setIsOpen: setIsDressCodeOpen,
    handleInputChange: handleDressCodeInputChange,
    handleSelect: handleSelectDressCode,
    handleDelete: handleDeleteDressCode,
  };

  return (
    <div>
      {/* Vérifiez si les dress codes sont en cours de chargement et affichez un spinner si c'est le cas */}
      {dressCodesLoading ? (
        <LoaderSpinner />
      ) : dressCodesError ? (
        <Text color="red.500">
          Erreur lors de la récupération des dress codes.
        </Text>
      ) : (
        <CustomSelectModal
          isDisabled={isDisabled}
          id="dressCodeSelector"
          label="DressCode"
          options={dressCode}
          {...dressCodeState}
          handleOpenModal={handleOpenModal}
        />
      )}

      <NewDressCodeModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={(name, description) => handleAddDressCode(name, description)}
        name={dressCodeInput}
      />
    </div>
  );
};
