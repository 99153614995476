import { Box, Text } from "@chakra-ui/react";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

interface FileDropzoneProps {
  onFileDrop: (files: File[]) => void;
  id: string;
  label: string;
}

const FileDropzone: React.FC<FileDropzoneProps> = ({ onFileDrop, label }) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      onFileDrop(acceptedFiles);
    },
    [onFileDrop]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1, // Limiter à un seul fichier
  });

  return (
    <Box
      {...getRootProps()}
      p={4}
      borderWidth="1px"
      borderRadius="md"
      bg={isDragActive ? "gray.200" : "gray.100"} // changement de couleur quand un fichier est glissé dessus
      _hover={{
        bg: "gray.150", // changement de couleur au survol
        boxShadow: "md", // appliquer une ombre au survol
        borderColor: "gray.300", // changer la couleur de la bordure
        transition: "all 0.3s", // transition douce pour tous les changements
      }}
      cursor="pointer"
    >
      <input {...getInputProps()} />
      <Text textAlign="center">{label}</Text>
    </Box>
  );
};

export default FileDropzone;
