import {
  Avatar,
  Box,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  PlacementWithLogical,
  Text,
  useBreakpointValue,
  useToast,
} from "@chakra-ui/react";
import { FaUserAlt } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { useSection } from "../../../utils/context/SectionContext";
import {
  LOGIN_PAGE,
  MANAGE_PROFIL_PAGE,
} from "../../../utils/navigation/endpoints";
import { useAuth } from "../../Authentification/useAuth";

export const MenuAvatar = () => {
  //... autres fonctions et variables
  //const textSize = useBreakpointValue({ base: "sm", md: "md" });
  const toast = useToast();
  const { setSection } = useSection();
  const userDataString = localStorage.getItem("user");
  const userData = userDataString ? JSON.parse(userDataString) : null;
  const firstname = userData?.firstname || "Invité";
  const email = userData?.email || "Invité";
  const imagePath = "/images/logo/avatar1.png"; // Remplacez "nom_de_votre_image.png" par le nom réel de votre fichier image
  const { logout } = useAuth();
  const menuPlacement = useBreakpointValue({ base: "top", md: "top" }) as
    | PlacementWithLogical
    | undefined;

  const navigate = useNavigate();
  const onLogout = async () => {
    try {
      await logout();
      toast({
        title: "Déconnexion réussi",
        description: "Vous avez bien été déconnecté",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      navigate(LOGIN_PAGE);
    } catch (error) {
      navigate(LOGIN_PAGE);
      toast({
        title: "Erreur",
        description: "Erreur inconnu",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const navigateProfil = () => {
    setSection("profil");
    navigate(MANAGE_PROFIL_PAGE);
    // autres actions à effectuer lors de la navigation vers la section du profil
  };

  return (
    <Menu placement={menuPlacement} offset={[5, 30]}>
      <MenuButton as={Box} sx={{ cursor: "pointer" }}>
        <HStack spacing="3" ps="2">
          <Avatar name={firstname} src={imagePath} boxSize="10" />
          <Box>
            <Text color="on-accent" fontWeight="medium" fontSize="sm">
              {firstname}
            </Text>
            <Text color="on-accent-muted" fontSize="sm">
              {email}
            </Text>
          </Box>
        </HStack>
      </MenuButton>
      <MenuList
        color={"black"}
        zIndex={1000}
        boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
      >
        <MenuItem onClick={navigateProfil} icon={<FaUserAlt />}>
          Mon Compte
        </MenuItem>
        <MenuItem onClick={onLogout} icon={<FiLogOut />}>
          Déconnexion
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
