// EventDetailsDrawerBody.tsx
import { Divider, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { EvenementType } from "../../../utils/types/EventType";

interface EventDetailsDrawerBodyProps {
  event: EvenementType;
}

const EventDetailsDrawerBody: React.FC<EventDetailsDrawerBodyProps> = ({
  event,
}) => {
  return (
    <VStack spacing={4} align="stretch">
      <Text fontWeight="bold">Détails de l'événement:</Text>
      <Divider />
      <Text>
        <strong>Nom:</strong> {event.name_event}
      </Text>
      <Text>
        <strong>Equipe domicile :</strong>{" "}
        {event.home_team ? event.home_team.name : "Non spécifiée"}
      </Text>
      <Text>
        <strong>Equipe extérieur:</strong>
        {event.away_team ? event.away_team.name : "Non spécifiée"}
      </Text>
      <Text>
        <strong>Adresse:</strong>{" "}
        {event.place ? event.place.name : "Non spécifiée"}
      </Text>
      <Text>
        <strong>Catégorie:</strong>{" "}
        {event.category ? event.category.name : "Non spécifiée"}
      </Text>
      <Text>
        <strong>Date:</strong>{" "}
        {event.date_event
          ? new Date(event.date_event * 1000).toLocaleDateString("fr-FR", {
              weekday: "long",
              day: "2-digit",
              month: "long",
              year: "numeric",
            })
          : "Aucune date d'évènement spécifiée"}{" "}
      </Text>
      {/* Ajoutez plus de détails selon les données disponibles dans votre type EvenementType */}
    </VStack>
  );
};

export default EventDetailsDrawerBody;
