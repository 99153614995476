import { Flex } from "@chakra-ui/react";
import { Dispatch, SetStateAction } from "react";
import { useQueryClient } from "react-query";
import { GetInput } from "../../../utils/types/EventType";
import CustomSelectNoFormSecondary from "../modal/form/CustomSelectNoFormSecondary";

type ZoneSelectorProps = {
  zones: GetInput[];
  inputValue: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>; // <- Ajoutez ceci
  handleInputChange: (e: any) => void; // Ici, vous pourriez affiner le type `any` en fonction de vos besoins
  handleAdd: () => void;
  handleSelect: (zone: GetInput) => void;
  handleDelete: (zone: GetInput) => void;
};

export const ZoneSelectorSecondary: React.FC<ZoneSelectorProps> = ({
  zones,
  inputValue,
  isOpen,
  setIsOpen,
  handleInputChange,
  handleAdd,
  handleSelect,
  handleDelete,
}) => {
  const queryClient = useQueryClient();

  return (
    <Flex justify="center" my={4}>
      <CustomSelectNoFormSecondary
        id="zones"
        options={zones}
        inputValue={inputValue}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleInputChange={handleInputChange}
        handleAdd={handleAdd}
        handleSelect={handleSelect}
        handleDelete={handleDelete}
      />
    </Flex>
  );
};
