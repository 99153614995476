import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DepotComponent } from "../../components/Depot/DepotComponent";
import { FileSummaryComponent } from "../../components/Depot/FileSummaryComponent";

export const DepositView = () => {
  const methods = useForm();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [validFiles, setValidFiles] = useState<File[]>([]);
  const [invalidFiles, setInvalidFiles] = useState<File[]>([]);

  const handleFilesDropped = (validFiles: File[], invalidFiles: File[]) => {
    setValidFiles(validFiles);
    setInvalidFiles(invalidFiles);
  };

  const handleContinueToStepTwo = () => {
    if (validFiles.length > 0 || invalidFiles.length > 0) {
      setCurrentStep(2);
    } else {
      console.log("Veuillez déposer au moins un fichier avant de continuer.");
    }
  };
  const handleReturn = () => {
    setCurrentStep(1);
    setValidFiles([]);
    setInvalidFiles([]);
    methods.reset();
  };
  const handleSuccess = () => {
    setCurrentStep(3);
  };
  return (
    <FormProvider {...methods}>
      {currentStep === 1 && (
        <>
          <DepotComponent
            onFilesDropped={handleFilesDropped}
            currentStep={currentStep}
            handleContinueToStepTwo={handleContinueToStepTwo}
            methods={methods}
          />
        </>
      )}
      {currentStep === 2 && (
        <FileSummaryComponent
          validFiles={validFiles}
          invalidFiles={invalidFiles}
          currentStep={currentStep}
          onReturn={handleReturn}
          onSuccess={handleSuccess}
        />
      )}
      {currentStep === 3 && (
        <Box textAlign="center" py={10}>
          <Heading as="h2" color="primary">
            Dépôt Réussi
          </Heading>
          <Text>Le dépôt des bulletins de paie s'est bien passé.</Text>
          <Button mt={4} onClick={handleReturn}>
            Retour au dépôt
          </Button>
        </Box>
      )}
    </FormProvider>
  );
};
