import { API_NOTIFICATION, API_URL } from "./ApiPaths";

export const getNotification = async () => {
  const res = await fetch(API_URL + API_NOTIFICATION, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};

export const getLastNotification = async () => {
  const res = await fetch(API_URL + API_NOTIFICATION + "/latest", {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};
