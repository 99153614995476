import * as yup from "yup";

export const loginValidationSchema = yup
  .object({
    email: yup
      .string()
      .email("L'adresse email n'est pas valide")
      .min(8, "Votre email doit faire plus de 8 caractères de long")
      .required("Email obligatoire"),
    password: yup
      .string()
      .min(8, "Votre mot de passe doit faire plus de 8 caractères de long")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
        "Le mot de passe doit contenir au moins un caractère spécial et une majuscule"
      )
      .required("Mot de passe obligatoire"),
  })
  .required();
