import {
  Box,
  Flex,
  HStack,
  IconButton,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import { FaBlackTie, FaFileContract } from "react-icons/fa";
import { FiHelpCircle, FiMoreVertical, FiSettings } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  DEPOSITS_PAGE,
  TEMP_WORKERS_PAGE,
} from "../../../../utils/navigation/endpoints";
import { LogoClub } from "../../logo";
import { MenuAvatar } from "../MenuAvatar";
import { EventsCollapse } from "./EventsCollapse";
import { SidebarButton } from "./SidebarButton";

export const SideNavProtectedV2 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Flex as="section" minH="100vh" color={"white"}>
      <Stack
        flex="1"
        maxW={{ base: "full", sm: "xs" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
        bg="bg.accent.default"
        color="fg.accent.default"
        borderRightWidth="1px"
        justifyContent="space-between"
      >
        <Stack spacing="10">
          <LogoClub />
          <Stack spacing="1">
            <EventsCollapse />
            <SidebarButton
              leftIcon={<FaBlackTie />}
              onClick={() => navigate(TEMP_WORKERS_PAGE)}
            >
              Vacataires
            </SidebarButton>
            <SidebarButton
              leftIcon={<FaFileContract />}
              onClick={() => navigate(DEPOSITS_PAGE)}
            >
              Dépôts
            </SidebarButton>
          </Stack>
        </Stack>
        <Stack
          spacing="4"
          divider={<StackDivider borderColor="bg.accent.subtle" />}
        >
          <Box />
          <Stack spacing="1">
            <SidebarButton leftIcon={<FiHelpCircle />}>
              Centre d'aide
            </SidebarButton>
            <SidebarButton leftIcon={<FiSettings />}>Paramètres</SidebarButton>
          </Stack>
          <HStack spacing="3" justify="space-between">
            <HStack spacing="3">
              <MenuAvatar />
            </HStack>
            <IconButton
              variant="tertiary.accent"
              icon={<FiMoreVertical />}
              aria-label="Open Menu"
            />
          </HStack>
        </Stack>
      </Stack>
    </Flex>
  );
};
