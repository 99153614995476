import { DeleteIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SimpleGrid,
  Table,
  TableProps,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useBreakpointValue,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { motion } from "framer-motion";

import React, { useContext, useRef, useState } from "react";
import {
  FaChevronDown,
  FaChevronUp,
  FaClipboardList,
  FaDownload,
  FaEdit,
  FaEllipsisV,
  FaEye,
  FaFileSignature,
  FaPlus,
  FaTrash,
} from "react-icons/fa";

import { HotKeys } from "react-hotkeys";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../utils/context";
import { EVENTS_PAGE } from "../../utils/navigation/endpoints";
import { EvenementType } from "../../utils/types/EventType";
import { Roles } from "../../utils/types/roles.interface";
import EventDetailsDrawerBody from "./utils/EventDetailsDrawerBody";
import { generateEventXLSX, generateServiceXLSX } from "./zones/ExportExcel";

interface EventTableProps extends TableProps {
  data: EvenementType[];
  openEditEvent: (event: EvenementType) => void;
  openServiceModal: (event: EvenementType) => void;
  openDpaeModal: (eventId: string) => void;
  deleteCoop: (eventId: string, serviceId: string) => void;
  openDeleteModal: (eventId: string) => void;
}

export const EventTable: React.FC<EventTableProps> = ({
  data,
  openEditEvent,
  openServiceModal,
  openDpaeModal,
  deleteCoop,
  openDeleteModal,
  ...props
}) => {
  const keyMap = {
    EDIT_EVENT: ["command+e", "ctrl+e"],
    DOWNLOAD_TABLE: ["command+d", "ctrl+d"],
    GENERATE_DPAE: ["command+g", "ctrl+g"],
  };

  const createHandlers = (event: any) => ({
    EDIT_EVENT: () => openEditEvent(event),
    DOWNLOAD_TABLE: () => console.log("Download table"),
    GENERATE_DPAE: () => console.log("Generate DPAE"),
  });

  const [isLargerThan1280] = useMediaQuery("(min-width: 1280px)");
  const navigate = useNavigate();
  const { userRole } = useContext(AuthContext);

  const [isLowerThan1280] = useMediaQuery("(max-width: 1280px)");
  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const navigateZone = (eventId: string, serviceName: string) => {
    navigate(`${EVENTS_PAGE}/${eventId}/${serviceName}`);
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<HTMLButtonElement>(null);
  const [selectedEvent, setSelectedEvent] = useState<EvenementType | null>(
    null
  );
  const defaultColumnVisibility = {
    name: true,
    address: true,
    home_team: true,
    away_team: true,
    category: true,
    eventDate: true,
    actions: true,
  };

  // Utiliser la valeur par défaut si `useBreakpointValue` retourne `undefined`
  const columnVisibility =
    useBreakpointValue({
      base: {
        name: true,
        home_team: false,
        away_team: false,
        address: false,
        category: false,
        eventDate: false,
        actions: true,
      },
      sm: {
        name: true,
        home_team: false,
        away_team: false,
        address: false,
        category: false,
        eventDate: false,
        actions: true,
      },
      md: {
        name: true,
        home_team: true,
        away_team: true,
        address: false,
        category: false,
        eventDate: false,
        actions: true,
      },
      lg: {
        name: true,
        home_team: true,
        away_team: true,
        address: true,
        category: true,
        eventDate: true,
        actions: true,
      },
    }) || defaultColumnVisibility;

  return (
    <Box
      maxWidth="100vw"
      overflowX="auto"
      marginX="auto" // Ajoute une marge automatique sur les côtés horizontaux pour centrer la Box
    >
      {" "}
      <Table {...props} size="sm" marginX="auto">
        <Thead>
          <Tr>
            {columnVisibility.name && <Th py={3}>Nom</Th>}
            {columnVisibility.home_team && <Th py={3}>Équipe à domicile </Th>}
            {columnVisibility.away_team && (
              <Th py={3}>Équipe à l'extérieur </Th>
            )}
            {columnVisibility.address && <Th py={3}>Adresse</Th>}
            {columnVisibility.category && <Th py={3}>Catégorie</Th>}
            {columnVisibility.eventDate && <Th py={3}>Date de l'évènement</Th>}
            {columnVisibility.actions && <Th py={3}>Actions</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {data.map((event, index) => (
            <>
              <Tr key={event._id}>
                {columnVisibility.name && (
                  <Td
                    isTruncated
                    maxWidth={columnVisibility.name ? "200px" : "100px"}
                    onClick={() =>
                      setOpenIndex(openIndex === index ? null : index)
                    }
                    _hover={{
                      bg: "gray.100", // Changez cette couleur selon votre thème
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                  >
                    {event.name_event}
                  </Td>
                )}
                {columnVisibility.home_team && (
                  <Td
                    isTruncated
                    maxWidth={columnVisibility.home_team ? "150px" : "100px"}
                  >
                    {" "}
                    <Tooltip
                      label={
                        event.home_team
                          ? event.home_team.name
                          : "Pas d'équipe à domicile"
                      }
                      placement="top"
                      hasArrow
                    >
                      <Box isTruncated>
                        {event.home_team
                          ? event.home_team.name
                          : "Pas d'équipe à domicile"}
                      </Box>
                    </Tooltip>
                  </Td>
                )}
                {columnVisibility.away_team && (
                  <Td
                    isTruncated
                    maxWidth={columnVisibility.away_team ? "150px" : "100px"}
                  >
                    {" "}
                    <Tooltip
                      label={
                        event.away_team
                          ? event.away_team.name
                          : "Pas d'équipe à l'extèrieur"
                      }
                      placement="top"
                      hasArrow
                    >
                      <Box isTruncated>
                        {event.away_team
                          ? event.away_team.name
                          : "Pas d'équipe à l'extèrieur"}
                      </Box>
                    </Tooltip>
                  </Td>
                )}

                {columnVisibility.address && (
                  <Td
                    isTruncated
                    maxWidth={columnVisibility.address ? "150px" : "100px"}
                  >
                    {" "}
                    <Tooltip
                      label={
                        event.place
                          ? event.place.name
                          : "Pas d'adresse spécifiée"
                      }
                      placement="top"
                      hasArrow
                    >
                      <Box isTruncated>
                        {event.place
                          ? event.place.name
                          : "Pas d'adresse spécifiée"}
                      </Box>
                    </Tooltip>
                  </Td>
                )}
                {columnVisibility.category && (
                  <Td
                    isTruncated
                    maxWidth={columnVisibility.category ? "150px" : "100px"}
                  >
                    {event.category
                      ? event.category.name
                      : "Pas de catégorie spécifiée"}
                  </Td>
                )}
                {columnVisibility.eventDate && (
                  <Td
                    isTruncated
                    maxWidth={columnVisibility.eventDate ? "250px" : "200px"}
                  >
                    {event.date_event
                      ? new Date(event.date_event * 1000).toLocaleDateString(
                          "fr-FR",
                          {
                            weekday: "long",
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }
                        )
                      : "Aucune date d'évènement spécifiée"}
                  </Td>
                )}

                <Td
                  isTruncated
                  maxWidth={columnVisibility.actions ? "350px" : "250px"}
                >
                  <HotKeys keyMap={keyMap} handlers={createHandlers(event)}>
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        isDisabled={userRole == Roles.LimitedAdmin}
                        aria-label="Plus d'options"
                        icon={<FaEllipsisV />}
                        variant="outline"
                        mr={2}
                      />
                      <MenuList>
                        {/* Télécharger le tableau */}
                        <MenuItem
                          icon={<FaDownload />}
                          isDisabled={userRole == Roles.LimitedAdmin}
                          onClick={() => generateEventXLSX(event)}
                          style={{ color: "green" }}
                          command="Ctrl+D"
                        >
                          Télécharger le tableau
                        </MenuItem>

                        {/* Génération des DPAE */}
                        <MenuItem
                          icon={<FaFileSignature />}
                          isDisabled={userRole == Roles.LimitedAdmin}
                          style={{ color: "orange" }}
                          onClick={() =>
                            openDpaeModal(event._id ? event._id : "")
                          }
                          command="Ctrl+G"
                        >
                          Génération des DPAE
                        </MenuItem>

                        {/* Modifier */}
                        <MenuItem
                          icon={<FaEdit />}
                          style={{ color: "blue" }}
                          isDisabled={userRole == Roles.LimitedAdmin}
                          command="Ctrl+E"
                          onClick={() => openEditEvent(event)}
                        >
                          Modifier
                        </MenuItem>

                        {/* Supprimer */}
                        <MenuItem
                          icon={<FaTrash />}
                          style={{ color: "red" }}
                          isDisabled={userRole == Roles.LimitedAdmin}
                          onClick={() =>
                            event._id && openDeleteModal(event._id)
                          }
                        >
                          Supprimer
                        </MenuItem>
                      </MenuList>
                    </Menu>

                    {/* Autres boutons, y compris le bouton pour détails/chevron */}
                    {isLargerThan1280 && (
                      <IconButton
                        ref={btnRef}
                        colorScheme="teal"
                        onClick={() =>
                          setOpenIndex(openIndex === index ? null : index)
                        }
                        icon={
                          openIndex === index ? (
                            <FaChevronUp />
                          ) : (
                            <FaChevronDown />
                          )
                        }
                        aria-label="Ouvrir les détails"
                      />
                    )}
                    {!isLargerThan1280 && (
                      <IconButton
                        ref={btnRef}
                        colorScheme="teal"
                        onClick={() => {
                          onOpen();
                          setSelectedEvent(event);
                        }}
                        icon={<FaEye />}
                        aria-label="View details"
                      />
                    )}
                  </HotKeys>
                </Td>
              </Tr>
              {openIndex === index && (
                <Tr>
                  <Td colSpan={8}>
                    <Box width="100%">
                      <motion.div
                        initial={{ maxHeight: 0 }}
                        animate={{ maxHeight: openIndex === index ? 300 : 0 }}
                        transition={{ duration: 0.5, ease: "easeInOut" }}
                        style={{
                          overflowY: "auto",
                        }}
                      >
                        <Table variant="simple" width="100%">
                          <Thead>
                            <Tr>
                              <Th width="80%">Services</Th>{" "}
                              <Th width="20%">Actions</Th>{" "}
                            </Tr>
                          </Thead>
                          <Tbody>
                            {event.coop && event.coop.length > 0
                              ? event.coop.map((service, serviceIndex) => (
                                  <Tr key={serviceIndex}>
                                    <Td>{service.name}</Td>
                                    <Td>
                                      <HStack spacing="1">
                                        <Tooltip
                                          label={"Télécharger le tableau"}
                                          placement="top"
                                          hasArrow
                                        >
                                          <IconButton
                                            id={`tableau${serviceIndex}`}
                                            isDisabled={
                                              userRole == Roles.LimitedAdmin
                                            }
                                            color="green"
                                            onClick={() =>
                                              generateServiceXLSX(
                                                service,
                                                event
                                              )
                                            }
                                            icon={
                                              <FaDownload fontSize="1.25rem" />
                                            }
                                            variant="ghost"
                                            aria-label="Télécharger le tableau"
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label={"Accéder au service"}
                                          placement="top"
                                          hasArrow
                                        >
                                          <IconButton
                                            id={`accessService${serviceIndex}`}
                                            color="secondary"
                                            onClick={() => {
                                              if (event._id && service.name) {
                                                navigateZone(
                                                  event._id,
                                                  service.name
                                                );
                                              } else {
                                                console.error(
                                                  "ID de l'événement ou nom du service manquant"
                                                );
                                              }
                                            }}
                                            icon={
                                              <FaClipboardList fontSize="1.25rem" />
                                            }
                                            variant="ghost"
                                            aria-label="Edit service"
                                          />
                                        </Tooltip>
                                        <Tooltip
                                          label={"Supprimer le service"}
                                          isDisabled={
                                            userRole == Roles.LimitedAdmin
                                          }
                                          placement="top"
                                          hasArrow
                                        >
                                          <IconButton
                                            id={`deleteService${serviceIndex}`}
                                            color="red"
                                            onClick={() => {
                                              if (event._id && service._id) {
                                                deleteCoop(
                                                  event._id,
                                                  service._id
                                                );
                                              } else {
                                                // Handle the error (e.g., display a message)
                                              }
                                            }}
                                            icon={
                                              <DeleteIcon fontSize="1.25rem" />
                                            }
                                            variant="ghost"
                                            aria-label="Delete service"
                                          />
                                        </Tooltip>
                                      </HStack>
                                    </Td>
                                  </Tr>
                                ))
                              : null}
                            <Tr
                              _hover={{
                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                cursor: "pointer",
                              }}
                              onClick={() => openServiceModal(event)}
                            >
                              <Td
                                colSpan={1}
                                color="gray.500"
                                fontWeight="light"
                                fontStyle="italic"
                              >
                                Ajouter un nouveau service
                              </Td>
                              <Td>
                                <IconButton
                                  id="addService"
                                  color="blue"
                                  onClick={() => openServiceModal(event)}
                                  icon={<FaPlus fontSize="1.25rem" />}
                                  variant="ghost"
                                  aria-label="Add new service"
                                />
                              </Td>
                            </Tr>
                          </Tbody>
                        </Table>
                      </motion.div>
                    </Box>
                  </Td>
                </Tr>
              )}
            </>
          ))}
        </Tbody>
      </Table>
      {!isLargerThan1280 && (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={() => {
            onClose();
            setSelectedEvent(null); // Réinitialiser l'événement sélectionné lors de la fermeture du Drawer
          }}
          finalFocusRef={btnRef}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>
              {selectedEvent ? selectedEvent.name_event : ""}
            </DrawerHeader>
            <DrawerBody>
              {selectedEvent ? (
                <EventDetailsDrawerBody event={selectedEvent} />
              ) : (
                <Text>Aucun événement sélectionné</Text>
              )}
            </DrawerBody>
            <DrawerFooter>
              <SimpleGrid columns={{ base: 2, sm: 2, md: 2 }} spacing={2}>
                {selectedEvent?.coop?.map((service: any, index: number) => (
                  <Button
                    key={index}
                    variant="outline"
                    color="secondary"
                    onClick={() => {
                      if (selectedEvent?._id && service.name) {
                        navigateZone(selectedEvent._id, service.name);
                      } else {
                        console.error(
                          "ID de l'événement ou nom du service manquant"
                        );
                      }
                    }}
                  >
                    {service.name}
                  </Button>
                ))}
              </SimpleGrid>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Box>
  );
};
