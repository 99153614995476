import { API_TEAMS, API_URL } from "../ApiPaths";

/** TEAM */
export const getTeams = async () => {
  const res = await fetch(API_URL + API_TEAMS, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};
export const addNewTeam = async (newTeam: string) => {
  const res = await fetch(API_URL + API_TEAMS, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name: newTeam }),
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return res.json();
};

export const deleteTeam = async (teamId: string) => {
  const res = await fetch(`${API_URL}${API_TEAMS}/${teamId}`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });

  if (!res.ok) {
    throw new Error("Network response was not ok");
  }

  return res.json();
};
