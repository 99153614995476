import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { API_LOGIN, API_MAGIC_LINK, API_URL } from "../../api";
import {
  CONFIRM_CONVOC_PAGE,
  VACATAIRE_PROFIL_PAGE,
} from "../../utils/navigation/endpoints";
import { useAuth } from "./useAuth";

const TemporaryLogin = () => {
  const location = useLocation();
  const { login } = useAuth();
  const navigate = useNavigate();
  const { validationCode } = useParams();
  const queryParams = new URLSearchParams(location.search);
  const validationCodeFromQuery = queryParams.get("validationCode");
  const actualValidationCode = validationCode || validationCodeFromQuery;

  useEffect(() => {
    const existingParams = location.search;
    const responsesStatus = queryParams.get("responsesStatus");
    console.log("temporary login");
    console.log(responsesStatus);

    console.log(actualValidationCode);
    const fetchData = async () => {
      try {
        // Log du validationCode avant de l'envoyer
        const response = await fetch(API_URL + API_LOGIN + API_MAGIC_LINK, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ validationCode: actualValidationCode }),
        });

        if (response.ok) {
          const text = await response.text();

          try {
            const data = JSON.parse(text);
            localStorage.setItem("eventsid", data.token);
            login(data.token, data.user);
            if (responsesStatus) {
              navigate(CONFIRM_CONVOC_PAGE + location.search); // Remplacez CONVOC_PAGE par le chemin réel de votre page de convocation
            } else if (location.search) {
              navigate(VACATAIRE_PROFIL_PAGE);
            } else {
              navigate(VACATAIRE_PROFIL_PAGE);
            }
          } catch (e) {
            console.error("Erreur lors de la conversion en JSON:", e);
          }
        } else {
        }
      } catch (error) {
        console.error("Erreur lors de la validation du lien magique:", error);
      }
    };

    fetchData();
  }, [validationCode]);

  return (
    <div>Connexion en cours...</div> // Vous pouvez afficher un spinner ou autre indication de chargement ici
  );

  return (
    <div>Connexion en cours...</div> // Vous pouvez afficher un spinner ou autre indication de chargement ici
  );
};

export default TemporaryLogin;
