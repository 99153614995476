import { Vacataire } from "../utils/types";
import {
  API_ACTIVE,
  API_ARCHIVE,
  API_ME,
  API_RELANCE,
  API_URL,
  API_VACATAIRE,
} from "./ApiPaths";
import { ERROR_UNKNOWN } from "./auth";

/** Get Vacataire */
export const getVacataire = async () => {
  return fetch(API_URL + API_VACATAIRE + API_ACTIVE, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

export const getVacataireArchived = async () => {
  return fetch(API_URL + API_VACATAIRE + API_ARCHIVE, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};
/** Get One Vacataire */
export const getOneVacataire = async (id: string) => {
  return fetch(API_URL + API_VACATAIRE + "/" + id, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};
/** Create Vacataire */
export const createVacataire = async (data: Vacataire) => {
  return fetch(API_URL + API_VACATAIRE, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // Passez directement l'objet data
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

/** Update Vacataire */
export const updateVacataire = async (data: Vacataire) => {
  const { _id, __v, archived, ...updateData } = data;

  return fetch(API_URL + API_VACATAIRE + "/" + data._id, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateData),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

/** Archive Vacataire */
export const archiveVacataire = async (data?: Vacataire) => {
  return fetch(API_URL + API_VACATAIRE + "/" + data?._id + API_ARCHIVE, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data), // Passez directement l'objet data
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json();
    })
    .catch((error) => {
      console.error(error);
      throw ERROR_UNKNOWN;
    });
};

/** Relance Vacatire */
export const relanceVacataire = async (vacataireId: string) => {
  const response = await fetch(
    API_URL + API_VACATAIRE + API_RELANCE + "/" + vacataireId,
    {
      method: "POST",
      credentials: "include",
    }
  );

  if (!response.ok) {
    throw new Error(
      `Erreur lors de la relance du vacataire: ${response.statusText}`
    );
  }
};

/** Archive Vacataire */

export const fetchMeVacataire = async () => {
  const res = await fetch(API_URL + API_VACATAIRE + API_ME, {
    method: "GET",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};

export const updateMeVacataire = async (data: Vacataire) => {
  const { _id, __v, archived, ...updateData } = data;

  const res = await fetch(API_URL + API_VACATAIRE + API_ME, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updateData),
  });
  if (!res.ok) {
    throw new Error("Network response was not ok");
  }
  return res.json();
};
