import React, { useEffect, useState } from "react";
import AuthService from "../../api/auth/Service";
import { AuthContext } from "../context/AuthContext";
import { AdminRoles, Roles } from "../types/roles.interface";

interface AuthProviderProps {
  children: React.ReactNode;
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [token, setToken] = useState<string | null>(
    localStorage.getItem("eventsid")
  );
  const [user, setUser] = useState<any>(null);
  const [userRole, setUserRole] = useState<Roles | AdminRoles | null>(null); // Ajout d'un nouvel état pour userRole

  useEffect(() => {
    const storedToken = localStorage.getItem("eventsid");
    const storedUser = localStorage.getItem("user");
    if (storedToken) {
      setToken(storedToken);
    }
    if (storedUser) {
      const parsedUser = JSON.parse(storedUser);
      setUser(parsedUser);
      setUserRole(parsedUser.role);
    }
  }, []);

  const login = (newToken: string, newUser: any) => {
    console.log(newUser);
    setToken(newToken);
    setUser(newUser);
    setUserRole(newUser.role); // Mettre à jour le rôle de l'utilisateur lors de la connexion
    localStorage.setItem("eventsid", newToken);
    localStorage.setItem("user", JSON.stringify(newUser));
  };

  const logout = () => {
    console.log("on logout");
    AuthService.logout();
    setToken(null);
    setUser(null);
    setUserRole(null); // Réinitialiser le rôle de l'utilisateur lors de la déconnexion

    localStorage.removeItem("eventsid");
    localStorage.removeItem("user");
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoggedIn: token !== null,
        token,
        userRole,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
