import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Text,
} from "@chakra-ui/react";
import { Controller, useFormContext } from "react-hook-form";
import { AddressForm } from "./AdressForm";

const DetailsProLegal = () => {
  const {
    register,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  return (
    <Box>
      <Box mt="4">
        <Text fontSize="1.5xl" fontFamily="montserrat" fontWeight="bold">
          Adresse Personnelle
        </Text>
        <AddressForm />
      </Box>

      <Box mt="8">
        <Text fontSize="1.5xl" fontFamily="montserrat" fontWeight="bold">
          Coordonnées bancaires{" "}
        </Text>

        <Grid
          templateColumns={{
            base: "1fr",
            md: "1fr 1fr",
            lg: "1fr 1fr",
          }}
          gap={4}
          mt={4}
        >
          <FormControl isInvalid={!!errors.iban} id="iban">
            <FormLabel>IBAN</FormLabel>
            <Controller
              name="iban"
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(e) => {
                    // Supprimer les espaces de la valeur saisie
                    const valueWithoutSpaces = e.target.value.replace(
                      /\s+/g,
                      ""
                    );
                    field.onChange(valueWithoutSpaces);
                  }}
                  maxLength={27}
                />
              )}
            />
            {errors.iban && (
              <FormErrorMessage>
                {errors.iban?.message as string}
              </FormErrorMessage>
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.bic} id="bic">
            <FormLabel>BIC</FormLabel>
            <Controller
              name="bic"
              render={({ field }) => <Input {...field} maxLength={11} />}
            />
            {errors.bic && (
              <FormErrorMessage>
                {errors.bic?.message as string}
              </FormErrorMessage>
            )}
          </FormControl>
        </Grid>
      </Box>
    </Box>
  );
};

export default DetailsProLegal;
