import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import * as Yup from "yup";
import { addNewCoop, deleteCoop, getCoop } from "../../../api/orga/Coop";
import { EvenementType, GetInput } from "../../../utils/types/EventType";
import CustomSelect from "./form/CustomSelect";

interface CreateEditServiceProps {
  selectedEvent?: Partial<EvenementType> | undefined;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
}
const CoopSchema = Yup.object().shape({
  coop: Yup.object().shape({
    _id: Yup.string().required("L'ID du service est obligatoire"),
    name: Yup.string().required("Le nom du service est obligatoire"),
  }),
});

const CreateEditService: React.FC<CreateEditServiceProps> = ({
  selectedEvent,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const methods = useForm({
    resolver: yupResolver(CoopSchema),
    defaultValues: {
      coop: {
        _id: "",
        name: "",
      },
    },
  });
  const { watch } = methods; // Déplacé à l'intérieur du composant qui utilise useForm
  const coopValue = watch("coop");
  console.log("Current value of coop:", coopValue);
  //Services
  const [coopInput, setCoopInput] = useState("");
  const [isCoopOpen, setIsCoopOpen] = useState(false);

  const {
    data: coops,
    isLoading: coopLoading,
    error: servicesError,
  } = useQuery<GetInput[], Error>("coop", getCoop);
  const queryClient = useQueryClient();

  const toast = useToast();
  const handleCoopInputChange = (e: any) => setCoopInput(e.target.value);

  const addCoopMutation = useMutation(addNewCoop, {
    onSuccess: () => {
      queryClient.invalidateQueries("coop");
    },
  });

  const deleteCoopMutation = useMutation(deleteCoop, {
    onSettled: () => {
      queryClient.invalidateQueries("coop");
    },
  });

  const handleAddCoop = () => {
    if (
      coopInput &&
      !coops?.some((coop: GetInput) => coop.name === coopInput)
    ) {
      addCoopMutation.mutate(coopInput);
      toast({
        title: `Service ajouté`,
        description: `Le service ${coopInput} a été ajouté`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      setCoopInput("");
    }
  };

  const handleSelectCoop = (coop: GetInput) => {
    console.log("Selected coop:", coop); // Affiche l'objet "coop" sélectionné

    setCoopInput(coop.name);
    setIsCoopOpen(false);
  };

  const handleDeleteCoop = (coop: GetInput) => {
    deleteCoopMutation.mutate(coop._id);
    toast({
      title: `Service supprimé`,
      description: `Le service ${coop.name} a été supprimé`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    setCoopInput("");
  };

  const coopState = {
    inputValue: coopInput,
    isOpen: isCoopOpen,
    setIsOpen: setIsCoopOpen,
    handleInputChange: handleCoopInputChange,
    handleAdd: handleAddCoop,
    handleSelect: handleSelectCoop,
    handleDelete: handleDeleteCoop,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent
        mx={{ base: 4, md: 15 }}
        maxW={{ base: "95vw", md: "50vw" }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            {" "}
            <ModalHeader color="primary">Sélectionner un service</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Controller
                name="coop"
                render={({ field }) => (
                  <CustomSelect
                    id="coop"
                    label="Services"
                    options={coops}
                    {...coopState}
                    onChange={(coop: any) => {
                      console.log("Setting coop value:", coop);

                      handleSelectCoop(coop);
                      field.onChange(coop);
                    }}
                  />
                )}
              />
              {Object.keys(methods.formState.errors).length > 0 && (
                <div>
                  <h2>Errors:</h2>
                  <pre>{JSON.stringify(methods.formState.errors, null, 2)}</pre>
                </div>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                mr={3}
                onClick={onClose}
                variant="outline"
                borderColor="red"
              >
                Annuler
              </Button>
              <Button type="submit" variant="primaryButton">
                {"Enregistrer"}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

export default CreateEditService;
