import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useRef } from "react";
import { Vacataire } from "../../../utils/types";

interface RelanceVacataireModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  vacataire: Vacataire | undefined;
}
export const RelanceVacataireModal: React.FC<RelanceVacataireModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  vacataire,
}) => {
  const cancelRef = useRef();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx={{ base: 4, md: 15 }}>
        <ModalHeader fontSize="lg" fontWeight="bold" color="primary">
          Relance pour compléter le profil
        </ModalHeader>

        <ModalBody>
          Êtes-vous sûr de vouloir relancer {vacataire?.firstname}{" "}
          {vacataire?.name} ?
        </ModalBody>

        <ModalFooter>
          <Button onClick={onClose}>Annuler</Button>
          <Button variant="primaryButton" onClick={onConfirm} ml={3}>
            Relancer
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
