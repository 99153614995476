import { Box, Image } from "@chakra-ui/react";
export const LogoClub = () => {
  return (
    <Box display="flex">
      <Image
        margin="auto"
        height={100}
        src="/images/logo/logo_club.png"
        alt="Logo Club"
      />
    </Box>
  );
};
