export enum Roles {
  Guest = "Guest",
  Vacataire = "Vacataire",
  LimitedAdmin = "LimitedAdmin",
  SemiLAdmin = "SemitLAdmin",
  Admin = "Admin",
}
export enum AdminRoles {
  SuperAdmin = "SuperAdmin",
  Admin = "Admin",
}
