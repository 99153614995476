export const HOME_PAGE = "/";
export const LOGIN_PAGE = "/connexion";
export const TEMP_WORKERS_PAGE = "/vacataires";
export const EVENTS_PAGE = "/evenements";
export const DEPOSITS_PAGE = "/depots";
export const NOTIFICATION_PAGE = "/Notifications";

export const SIGN_UP_PAGE = "/inscription";
export const VERIF_EMAIL_PAGE = "/inscription/:id/validation";
export const RESET_PASSWORD_PAGE = "/recuperation-mot-de-paXrxRsse";
export const RESET_PASSWORD_CONFIRM_PAGE =
  "/recuperation-mot-de-passe/validation";

export const UPDATE_PASSWORD_PAGE = "/profil/changement-mot-de-passe";
export const UPDATE_EMAIL_PAGE = "/profil/changement-email";
export const MANAGE_PROFIL_PAGE = "/profil/gestion-profil";

export const HERITAGE_PAGE = "/patrimoine";
export const CONTRACT_PAGE = "/contrat";
export const HELP_PAGE = "/aide";
export const VACATAIRE_PROFIL_PAGE = "/vacataires/profil";
export const VACATAIRE_DOCUMENT_PAGE = "/vacataires/documents";

export const CONFIRM_CONVOC_PAGE = "/update-status";

export const PUBLIC_LOGIN_PAGE = "/connexion-vacataire/:validationCode?";
export const Viewing_page = "/file-viewer";
