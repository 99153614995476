// FileViewerPage.tsx
import { Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSearchParams } from "react-router-dom";
import { API_URL } from "../../api";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const getFileType = (url: string) => {
  if (url.endsWith(".pdf")) return "pdf";
  if (url.endsWith(".png") || url.endsWith(".jpg") || url.endsWith(".jpeg"))
    return "image";
  return "unknown";
};
const FileViewerPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  const fileURL = searchParams.get("file") || "";
  const [actualFileURL, setActualFileURL] = useState<string | null>(null);

  const requestedFileURL = searchParams.get("file") || "";

  useEffect(() => {
    const parsedURL = new URL(requestedFileURL);
    const pathParts = parsedURL.pathname.split("/");
    const idAndType = `${pathParts[pathParts.length - 2]}/${
      pathParts[pathParts.length - 1]
    }`;

    // Utilisez idAndType pour obtenir le chemin exact du fichier
    fetch(`${API_URL}/vacataires/get-file-path/${idAndType}`, {
      credentials: "include",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            "Erreur lors de la récupération du chemin du fichier"
          );
        }
        return response.text();
      })
      .then((path) => {
        // Utilisez le chemin obtenu pour construire l'URL de serve-file
        const fileServingURL = `${API_URL}/${path}`;
        setActualFileURL(fileServingURL);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [requestedFileURL]);

  const fileType = getFileType(actualFileURL || ""); // Utilisez la fonction pour déterminer le type de fichier

  return (
    <Flex
      width="100%"
      height="100vh"
      alignItems="center"
      justifyContent="center"
    >
      {fileType === "pdf" ? (
        <Document
          file={actualFileURL}
          onError={(error) => console.error(error.message)}
        >
          <Page pageNumber={1} />
        </Document>
      ) : fileType === "image" ? (
        <img
          src={actualFileURL ? actualFileURL : ""}
          alt="Visualisation du fichier"
          style={{ maxWidth: "80%", maxHeight: "80%" }}
        />
      ) : (
        <p>Type de fichier non pris en charge par le visualisateur.</p>
      )}
    </Flex>
  );
};

export default FileViewerPage;
