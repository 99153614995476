import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { API_URL } from "../../api";
import PaySlipGuestComponent from "./PaySlipView";

const DocumentsVacataireView: React.FC = () => {
  const [paySlipData, setPaySlipData] = useState<string[]>([]);
  const [contractData, setContractData] = useState<string[]>([]);

  useEffect(() => {
    loadVacataireData();
  }, []);

  const loadVacataireData = async () => {
    try {
      const response = await fetch(`${API_URL}/vacataires/me`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(
          "Problème lors de la récupération des données du vacataire."
        );
      }

      const vacataire = await response.json();
      setPaySlipData(vacataire.pay_slip || []);
      setContractData(vacataire.contrats || []);
    } catch (error) {
      console.error("Erreur lors de la récupération des données:", error);
    }
  };

  return (
    <Box>
      <Tabs size="md" variant="enclosed" color="primary">
        <TabList mb="1em">
          <Tab>Bulletin de paie</Tab>
          <Tab>Contrats</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <PaySlipGuestComponent paySlipData={paySlipData} />
          </TabPanel>
          <TabPanel>
            <Text
              fontSize="2.5xl"
              fontFamily="montserrat"
              fontWeight="bold"
              marginTop={"4"}
            >
              Mes contrats
            </Text>{" "}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default DocumentsVacataireView;
